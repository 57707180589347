import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function ConfirmEmail() {
  return (
   <>
     <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>
 <Header />
<section className="login-page">
  <img src="images/logo2.svg" alt="" className="img1" />
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-6">
      <h2> Confirm your Email Address </h2>
      <h4 style={{fontSize : "20px",margin : "0px 0px 20px", lineHeight:"29px"}}>Thank you for joining the MapMetrics business portal </h4>
      <h6> To finish signing up, please confirm your email address. </h6>
      </div>
    </div>
  </div>
</section>
<Footer />
   </>
  )
}

export default ConfirmEmail