import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const DonutGenderChart = (props) => {
    const [options] = useState({
        chart: {
            type: 'donut',
            width: '100%'
        },
        labels: ['Male', 'Female', 'Other'],
        series: [props.male, props.female, props.other],
        colors: [ '#DF5D5D', '#53D6B7', '#F2F2F2'],
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 500,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]

    });

    return (
        <div id="chart">
            <ReactApexChart options={options} series={options.series} type="donut" width={250} />
        </div>
    );
}

export default DonutGenderChart;