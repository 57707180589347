import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import CampaignChartYear from "../Components/CampaignChartYear";
import DonutChart from "../Components/DonutChart";
// import '../css/style.css';
// import '../css/style.css.map';
// import '../css/style.scss';
// import '../css/responsive.css';
// import '../css/bootstrap.min.css';
// import '../css/slimNav_sk78.css';
import { Navigate, redirect, useNavigate } from "react-router-dom";
import Axios from "axios";
import CampaignChartDays from "../Components/CampaignChartDays";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

let getCampaignStatsMonthly;

function Dashboard() {
  const navigate = useNavigate();

  const isAuthenticated =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const User =
    JSON.parse(localStorage.getItem("User")) ||
    JSON.parse(sessionStorage.getItem("User"));

  const [campaigns, setCampaigns] = useState([]);

  const [type, setType] = useState("year");

  const [IMPR_ROAD_DATA, SetIMPR_ROAD_DATA] = useState("");
  const [IMPR_WALLET_DATA, SetIMPR_WALLET_DATA] = useState("");
  const [CLICKS_LINK_DATA, SetCLICKS_LINK_DATA] = useState("");
  const [CLICKS_WALLET_DATA, SetCLICKS_WALLET_DATA] = useState("");

  const [selectedMonth, setSelectedMonth] = useState(null);

  let IMPR_ROAD = [];
  let IMPR_WALLET = [];
  let CLICKS_LINK = [];
  let CLICKS_WALLET = [];

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  const daysInMonth = new Date(year, selectedMonth + 1, 1, -1).getDate();

  const daysArray = [];
  for (let i = 1; i <= daysInMonth; i++) {
    daysArray.push(i.toString());
  }

  const initImpressions = (
    impr_road,
    impr_wallet,
    clicks_wallet,
    clicks_link
  ) => {
    if (impr_road) {
      for (let i = 1; i <= daysArray.length; i++) {
        if (impr_road.length > 0) {
          impr_road.map((impr) => {
            if (impr._id.day === i) {
              IMPR_ROAD[i] = impr.count;
            } else {
              IMPR_ROAD.push(0);
            }
          });
        } else {
          IMPR_ROAD.push(0);
        }
      }
    }
    if (impr_wallet) {
      for (let i = 1; i <= daysArray.length; i++) {
        if (impr_wallet.length > 0) {
          impr_wallet.map((impr) => {
            if (impr._id.day === i) {
              IMPR_WALLET[i] = impr.count;
            } else {
              IMPR_WALLET.push(0);
            }
          });
        } else {
          IMPR_WALLET.push(0);
        }
      }
    }
    if (clicks_link) {
      for (let i = 1; i <= daysArray.length; i++) {
        if (clicks_link.length > 0) {
          clicks_link.map((impr) => {
            if (impr._id.day === i) {
              CLICKS_LINK[i] = impr.count;
            } else {
              CLICKS_LINK.push(0);
            }
          });
        } else {
          CLICKS_LINK.push(0);
        }
      }
    }
    if (clicks_wallet) {
      for (let i = 1; i <= daysArray.length; i++) {
        if (clicks_wallet.length > 0) {
          clicks_wallet.map((impr) => {
            if (impr._id.day === i) {
              CLICKS_WALLET[i] = impr.count;
            } else {
              CLICKS_WALLET.push(0);
            }
          });
        } else {
          CLICKS_WALLET.push(0);
        }
      }
    }
    SetIMPR_ROAD_DATA(IMPR_ROAD.slice(1,daysArray.length));
    SetIMPR_WALLET_DATA(IMPR_WALLET.slice(1,daysArray.length));
    SetCLICKS_LINK_DATA(CLICKS_LINK.slice(1,daysArray.length));
    SetCLICKS_WALLET_DATA(CLICKS_WALLET.slice(1,daysArray.length));

    // SetIMPR_ROAD_DATA(IMPR_ROAD);
    // SetIMPR_WALLET_DATA(IMPR_WALLET);
    // SetCLICKS_LINK_DATA(CLICKS_LINK);
    // SetCLICKS_WALLET_DATA(CLICKS_WALLET);
    setLoading(false);
  };

  const getCampaigns = async () => {
    await Axios.get(
      `https://business-api.mapmetrics.org/campaign/get-campaigns-by-user/${User._id}`,
      { headers: { jwt: isAuthenticated } }
    )
      .then((res) => {
        console.log(res.data);
        setCampaigns(res.data.Campaigns);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        console.log(e.response.data);
        Error(e.response.data.message);
      });
  };

  const [impr_road, setImpr_Road] = useState([]);
  const [impr_wallet, setImpr_Wallet] = useState([]);
  const [clicks_wallet, setClicks_Wallet] = useState([]);
  const [clicks_link, setClicks_Link] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCampaignStatsYearly = async () => {
    setLoading(true);
    await Axios.get(
      `https://business-api.mapmetrics.org/campaign/get-campaign-stats?id=${User._id}&type=${type}`,
      { headers: { jwt: isAuthenticated } }
    )
      .then((res) => {
        console.log(res.data);
        setImpr_Road(res.data.impr_road);
        setImpr_Wallet(res.data.impr_wallet);
        setClicks_Wallet(res.data.clicks_wallet);
        setClicks_Link(res.data.clicks_link);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        console.log(e.response.data);
        Error(e.response.data.message);
      });
  };

  getCampaignStatsMonthly = async (type, month) => {
    setLoading(true);
    SetIMPR_ROAD_DATA("");
    SetIMPR_WALLET_DATA("");
    SetCLICKS_LINK_DATA("");
    SetCLICKS_WALLET_DATA("");
    await Axios.get(
      `https://business-api.mapmetrics.org/campaign/get-campaign-stats?id=${User._id}&type=${type}&month=${month}`,
      { headers: { jwt: isAuthenticated } }
    )
      .then((res) => {
        console.log(res.data);
        setSelectedMonth(month);
        setImpr_Road(res.data.impr_road);
        setImpr_Wallet(res.data.impr_wallet);
        setClicks_Wallet(res.data.clicks_wallet);
        setClicks_Link(res.data.clicks_link);

        initImpressions(
          res.data.impr_road,
          res.data.impr_wallet,
          res.data.clicks_wallet,
          res.data.clicks_link
        );
      })
      .catch((e) => {
        if (e.response.status == 401) {
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        console.log(e.response.data);
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    getCampaigns();
    getCampaignStatsYearly();
  }, [type]);

  return (
    <>
      {User && isAuthenticated ? (
        <>
          <Helmet>
            <meta name="robots" content="noindex,follow" />
          </Helmet>
          <Header />
          <ToastContainer autoClose={1000} />
          <section className="campaign-performance-page">
            <Sidebar />
            <div className="page-decp-dec">
              <div className="row">
                <div className="col-lg-12">
                  <div className="overview-title2">
                    <h3>Campaign Statistics</h3>
                    <p>
                      Monitor the performance of your ads and utilize the
                      acquired insights to improve their conversion rate.
                    </p>
                    {/* <ul>
            <li> <span /> Impressions Road</li>
            <li> <span className="color1" /> Clicks Wallet</li>
            <li> <span className="color2" /> Impressions Wallet</li>
            <li> <span className="color3" /> Clicks Link</li>
          </ul> */}

                    {/* <div class="chart-container">
            <canvas id="line-chartcanvas"></canvas>
          </div> */}
                    {/* <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height={350} />
          </div>  */}

                    {/* <img src="images/camp-pro-img8.svg" alt="" /> */}
                    {type == "month" ? (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "end",
                        }}
                      >
                        <p
                          onClick={() => setType("year")}
                          style={{
                            background: "#00A69F",
                            border: "1px solid #00A69F",
                            boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.043)",
                            borderRadius: "4px",
                            display: "table",
                            width: "4rem",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "22px",
                            textAlign: "center",
                            color: "#FFFFFF",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          Monthly
                        </p>
                      </div>
                    ) : null}

                    {loading == false && type == "year" ? (
                      <CampaignChartYear
                        type={type}
                        setType={setType}
                        impr_road={impr_road}
                        impr_wallet={impr_wallet}
                        clicks_link={clicks_link}
                        clicks_wallet={clicks_wallet}
                      />
                    ) : (
                      IMPR_ROAD_DATA.length > 0 && (
                        <CampaignChartDays
                          month={selectedMonth}
                          loading={loading}
                          impr_road={IMPR_ROAD_DATA}
                          impr_wallet={IMPR_WALLET_DATA}
                          clicks_link={CLICKS_LINK_DATA}
                          clicks_wallet={CLICKS_WALLET_DATA}
                        />
                      )
                    )}
                    <h3>Campaigns</h3>
                  </div>
                </div>
                <div className="col-lg-12">
                  {campaigns.map((campaign) => {
                    return (
                      <div className="wrap1">
                        <div className="sec4">
                          <div className="row">
                            <div className="col-lg-7">
                              <h2>
                                {" "}
                                <img
                                  src="images/camp-pro-img1.svg"
                                  alt=""
                                />{" "}
                                {campaign.campaign_title}
                              </h2>
                              <h5>Total campaign performance </h5>
                              <ul>
                                <li>
                                  <span data-descr="Drivers will see your brand logo and offering notification while using the app in navigation mode." />
                                  <p>Road Impressions</p>
                                  <h3>{campaign.impr_road.length}</h3>
                                </li>
                                <li>
                                  <span
                                    data-descr="Users will see your ad inside their wallet, with only the title visible, so make it interactive and inviting."
                                    className="color1"
                                  />
                                  <p>Wallet Impressions</p>
                                  <h3>{campaign.impr_wallet.length}</h3>
                                </li>
                                <li>
                                  <span
                                    data-descr="Monitor the number of times users clicked on your wallet ad by making the title catchy and straight to the point."
                                    className="color2"
                                  />
                                  <p>Wallet Clicks</p>
                                  <h3>{campaign.clicks_wallet.length}</h3>
                                </li>
                                <li>
                                  <span
                                    data-descr="Track the number of people clicking on your website link, and ensure your content is concise and compelling for better conversion rates."
                                    className="color3"
                                  />
                                  <p>Link Clicks</p>
                                  <h3>{campaign.clicks_link.length}</h3>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-5 text-center">
                              {/* <img src="images/camp-pro-img2.svg" alt="" className="img1" /> */}
                              <DonutChart
                                impr_road={campaign.impr_road.length}
                                impr_wallet={campaign.impr_wallet.length}
                                clicks_wallet={campaign.clicks_wallet.length}
                                clicks_link={campaign.clicks_link.length}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export { getCampaignStatsMonthly };
export default Dashboard;
