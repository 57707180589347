import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
// import '../css/style.css';
// import '../css/style.css.map';
// import '../css/style.scss';
// import '../css/responsive.css';
// import '../css/bootstrap.min.css';
// import '../css/slimNav_sk78.css';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Axios from 'axios';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DonutGenderChart from '../Components/DonutGenderChart';
import { Helmet } from 'react-helmet';

function CampaignPerformance() {

    const navigate = useNavigate ();

    const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");
    const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User"));

    const [ageModal, setAgeModal] = useState(false);
    const [campaign, setCampaign] = useState("");
    const [callApi, setCallApi] = useState(false);

    const [male, setMale] = useState("");
    const [female, setFemale] = useState("");
    const [other, setOther] = useState("");

    const [searchParams] = useSearchParams();

    const campaign_id = searchParams.get("id");

    let countMale = 0;
    let countFemale = 0;
    let countOther = 0;

    const getCampaign = async () => {

      await Axios.get(`https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/get-campaign/${campaign_id}`,{headers : {jwt : isAuthenticated}})
      .then((res) => {
        console.log(res.data);
        setCampaign(res.data.Campaign);

        res.data.Campaign.impr_road?.map((impr) => {
          if(impr.gender === "Male"){
            countMale = countMale + 1;
          }else if(impr.gender === "Female"){
            countFemale = countFemale + 1;
          }else{
            countOther = countOther + 1;
          }
        })

        res.data.Campaign.impr_wallet?.map((impr) => {
          if(impr.gender === "Male"){
            countMale = countMale + 1;
          }else if(impr.gender === "Female"){
            countFemale = countFemale + 1;
          }else{
            countOther = countOther + 1;
          }
        })

        res.data.Campaign.clicks_link?.map((impr) => {
          if(impr.gender === "Male"){
            countMale = countMale + 1;
          }else if(impr.gender === "Female"){
            countFemale = countFemale + 1;
          }else{
            countOther = countOther + 1;
          }
        })

        res.data.Campaign.clicks_wallet?.map((impr) => {
          if(impr.gender === "Male"){
            countMale = countMale + 1;
          }else if(impr.gender === "Female"){
            countFemale = countFemale + 1;
          }else{
            countOther = countOther + 1;
          }
        })



        setMale(countMale);

        setFemale(countFemale);

        setOther(countOther);


      }).catch((e) => {
        if(e.response.status == 401){
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate('/login');
        }
          console.log(e.response.data);
          Error(e.response.data.message);
      })  
    }

    console.log(male);
    console.log(female);
    console.log(other);

  
    const activateCampaign = async () => {
      await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/change-campaign-status/${campaign_id}`,
      { status : "active"},{headers : {jwt : isAuthenticated}})
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      }).catch((e) => {
      if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })  
    }

    const deactivateCampaign = async () => {
      await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/change-campaign-status/${campaign_id}`,
      { status : "inactive" },{headers : {jwt : isAuthenticated}})
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      }).catch((e) => {
        if(e.response.status == 401){
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate('/login');
        }
          console.log(e.response.data);
          Error(e.response.data.message);
      })  
    }

    const Error = (message) => {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    useEffect(() => {
      getCampaign();
    }, [callApi])
    

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>

    <Header />
    <ToastContainer autoClose={1000} />
    <section className="campaign-performance-page">
  <Sidebar />
  <div className="page-decp-dec">
    <div className="row">
      <div className="col-lg-4">
        <div className="wrap1">
          <div className="row sec1">
          <div className="col-lg-6 col-md-7">
              {campaign.image?
              <img src={campaign?.image} style={{objectFit:"cover",height:"6rem",width:"6rem"}} alt="" />
              :
              <img src="/images/camp-list-img2.svg" style={{objectFit:"cover",height:"6rem",width:"6rem"}} alt="" />
              }
              <h5>{campaign.campaign_title}</h5>
              <h6>Status: 
                <span style={campaign.active === "active"? {color:"#3EF0B0"} : 
              campaign.active === "inactive"? {color:"#999999"} : 
              campaign.active === "pending"? {color : "#F8B14F"} : 
              campaign.active === "rejected" ? {color : "#F03E3E"} : {color : "#00A69F"}}  >
                <i className="fa-solid fa-circle" /> {campaign.active === "active"? 
                "Active" : campaign.active === "inactive" ? 
                "Inactive" : campaign.active === "pending"? 
                "Pending" : campaign.active === "rejected"? "Rejected" : "Approved"
                }
                </span>
              </h6>
            </div>
            <div className="col-lg-6 col-md-5">
              <a href="#" className="pre-btn">Ad Preview</a>
            </div>
          </div>
          <div className="sec2">
            <h2>{campaign.ad_title}</h2>
            <p>{campaign.ad_description} </p>
            <ul>
              <li>
              <a href={campaign.landing_url} target="_blank" rel="noreferrer"> <i className="fa-solid fa-arrow-up-right-from-square" /> Visit now</a>
              </li>
              <li>
                <Link to="/edit-campaign" state={{ Campaign: campaign }}> <i className="fa-solid fa-pencil" /> </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="wrap1">
          <div className="sec3">
            <div className="row">
            <div className="col-lg-8 col-md-9">
                <form action>
                  <label htmlFor>Daily budget (mAds)*</label>
                  <div className="amount">
                    <i className="fa-solid fa-dollar-sign" />
                    <input type="text" disabled value={campaign.daily_budget?.toFixed(2)} />
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor>Start date</label>
                      <input type="date" value={moment(campaign.start_date).format("YYYY-MM-DD")} />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor>End date </label>
                      <input type="date" value={moment(campaign.end_date).format("YYYY-MM-DD")} />
                    </div>
                    <div className="col-lg-12">
                      <ul className="sub-btn">
                        <li><Link to="/edit-campaign" state={{ Campaign: campaign }}> Edit Budget </Link></li>
                        {/* <li><a href="#"><i className="fa-solid fa-download" /> Download PDF</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-4 col-md-3">
                <ul className="stop-btn">
                  <li><a href="#" ><i className="fa-solid fa-circle-play" style={campaign.active == "active"? {color : "#00a69f"} : {color : "#667085" }} onClick={activateCampaign} /></a></li>
                  <li><a href="#" ><i className="fa-solid fa-circle-pause" style={campaign.active == "inactive"? {color : "red"} : {color : "#667085" }} onClick={deactivateCampaign} /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <img className="map1" style={{maxHeight:"15rem",width:"100%",objectFit:"cover",marginBottom:"2rem"}} src='/images/world.png' ></img>
      </div>
          <div className="col-lg-8">
        <div className="wrap1">
          <div className="sec6">
            <h2>Campaign</h2>
            <p>Monitor your campaign performance</p>
             <ul>
              
              {campaign.impr_road&&
              <li>
                <span data-descr="Drivers will see your brand logo and offering notification while using the app in navigation mode." />
                <h5>Road Impressions</h5>
                <h3>{campaign.impr_road.length}</h3>
              </li>
              } 
              
              {campaign.impr_wallet&&
              <li>
                 <span
                    data-descr="Users will see your ad inside their wallet, with only the title visible, so make it interactive and inviting."
                    className="color1"
                  />
                <h5>Wallet Impressions</h5>
                <h3>{campaign.impr_wallet.length}</h3>
              </li>
              }

              {campaign.clicks_wallet&&
              <li>
                <span
                  data-descr="Monitor the number of times users clicked on your wallet ad by making the title catchy and straight to the point."
                  className="color2"
                />
                <h5>Wallet Clicks</h5>
                <h3>{campaign.clicks_wallet.length}</h3>
              </li>
              }

              {campaign.clicks_link&&
              <li>
                 <span
                  data-descr="Track the number of people clicking on your website link, and ensure your content is concise and compelling for better conversion rates."
                  className="color3"
                />
                <h5>Link Clicks</h5>
                <h3>{campaign.clicks_link.length}</h3>
              </li>
              }

            </ul> 
            <img src="images/camp-pro-img5.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="wrap1">
          <div className="sec5">
            <h2>Gender Ratio</h2>
            {male || female?
            <DonutGenderChart male={male} female={female} other={other} />
            :
            null
            }
            <ul className="gen-sec">

              {campaign.gender === "male" || campaign.gender === "all"?
              <li>
                <span className='color1'/> Male
              </li>
              :
              null
              }

              {campaign.gender === "female" || campaign.gender === "all"?
              <li>
                <span className="color" /> Female
              </li>
              : null
              }

              {campaign.gender === "all"?
              <li>
                <span className="color2" /> Other
              </li>
              :
              null
              }  

            </ul>
            <div className="age-wrpa">
              <div className="age-modal" style={ageModal? {display : "block"} : {display : "none"}}>
                <a href="#" className="close-modal" onClick={() => setAgeModal(false) } > <img src="/images/close-modal.svg" alt="" /> </a>
                <form action>
                  <div className="accro-sec1 active">
                    <h5> <img src="/images/camp-pro-img6.svg" alt="" /> Select gender <i className="fa-solid fa-chevron-down" /></h5>
                    <div className="decp">
                      <ul>
                        <li>
                          <label> <input type="checkbox" name id /> Female</label>
                        </li>
                        <li>
                          <label> <input type="checkbox" name id /> Male</label>
                        </li>
                        <li>
                          <label> <input type="checkbox" name id /> Others</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="accro-sec1">
                    <h5> <img src="/images/camp-pro-img7.svg" alt="" /> Select Date <i className="fa-solid fa-chevron-down" /></h5>
                    <div className="decp">
                      <input type="date" />
                    </div>
                  </div>
                  <ul className="btn-sec">
                    <li> <input type="submit" value="Apply" /> </li>
                    <li>
                      <input type="reset" value="Clear all" />
                    </li>
                  </ul>
                </form>
              </div>
              {/* <h2>Gender Ratio <a href="#"> <img src="/images/filter-icon.svg" alt="" onClick={() => setAgeModal(true)} /> </a></h2>
              <ul className="age-sec">

 
                <li>
                  <span /> 20-40
                </li>
             
                
                <li>
                  <span className="color1" /> 40-60
                </li>
                <li>
                  <span className="color2" /> 60+
                </li>
              </ul> */}
              <img src="images/camp-pro-img4.svg" alt="" className="img2" />
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>
</section>
<Footer />
    </>
  )
}

export default CampaignPerformance