// import '../src/css/bootstrap.min.css';
// import '../src/css/style.css';
// import '../src/css/responsive.css';




// import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './pages/Dashboard';
import BusinessRegistration from './pages/BusinessRegistration';
import Register from './pages/Register';
import Login from './pages/Login';
import AdManager from './pages/AdManager';

import Billing from './pages/Billing';
import BusinessDetails from './pages/BusinessDetails';
import CampaignPerformance from './pages/CampaignPerformance';
import CreateCampaign from './pages/CreateCampaign';
import CompaniesList from './pages/CompaniesList';
import Screening from './pages/Screening';
import EditCampaign from './pages/EditCampaign';
import Payment from './pages/Payment';
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import CompanyDetails from "./pages/CompanyDetails";
import ConfirmEmail from "./pages/ConfirmEmail";




function App() {

  const is_campaign = sessionStorage.getItem("campaign") || localStorage.getItem("campaign");

  return (
    <Router> 
          <Routes>
            <Route path="/" exact element={ <Dashboard /> } />
            <Route path="/ad-manager" exact element={<AdManager />} />
            <Route path="/super-admin/dashboard" exact element={<CompaniesList />} />
            <Route path="/super-admin/screening" exact element={<Screening />} />
            <Route path="/campaign-performance" exact element={<CampaignPerformance />} />
            <Route path="/create-campaign" exact element={<CreateCampaign />} />
            <Route path="/edit-campaign" exact element={<EditCampaign />} />
            <Route path="/create-account" exact element={<Register />} />
            <Route path="/business-registration" exact element={<BusinessRegistration />} />
            <Route path="/edit-profile" exact element={<BusinessRegistration />} />
            <Route path="/review-business-details" exact element={<BusinessDetails />} />
            <Route path="/company-details" exact element={<CompanyDetails />} />
            <Route path="/profile" element={<BusinessDetails />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/billing" exact element={<Billing />} />
            <Route path="/payment" exact element={<Payment />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route path="/reset-password/" exact element={<ResetPassword />} />
          </Routes>
      </Router> 
  );
}

export default App;
