import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function AdManager() {
  const navigate = useNavigate();

  const isAuthenticated =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const User =
    JSON.parse(localStorage.getItem("User")) ||
    JSON.parse(sessionStorage.getItem("User"));

  const [campaigns, setCampaigns] = useState([]);

  const [callApi, setCallApi] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const getCampaigns = async () => {
    await Axios.get(
      `https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/get-campaigns-by-user/${User._id}`,
      { headers: { jwt: isAuthenticated } }
    )
      .then((res) => {
        console.log(res.data);
        setCampaigns(res.data.Campaigns);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        console.log(e.response.data);
        Error(e.response.data.message);
      });
  };

  const RemoveCampaign = async (id) => {
    // alert(id);
    // return;
    await Axios.delete(
      `https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/delete-campaign/${id}`,
      { headers: { jwt: isAuthenticated } }
    )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        console.log(e.response.data);
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    getCampaigns();
  }, [callApi]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <Header />
      <ToastContainer autoClose={1000} />
      <section className="ad-manager-page">
        <Sidebar />
        <div className="page-decp-dec">
          <div className="row align-items-center">
            <div className="col-lg-6 col-5">
              <h2>Quick access</h2>
            </div>
            <div className="col-lg-6 col-7 text-end">
              <Link to="/create-campaign" className="campaign-btn">
                {" "}
                <i className="fa-solid fa-plus" /> Create new campaign
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="wrap">
                <div className="img-wrap">
                  <img src="/images/adm-img1.png" alt="" />
                  <h4>Crypto Project </h4>
                </div>
                <div className="decp">
                  <p>
                    Promote your crypto project, ICO, AMA, and more by reaching
                    out to crypto enthusiasts.
                  </p>
                  <a href="/create-campaign" className cret-camp-btn>
                    Create Campaign
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="wrap">
                <div className="img-wrap">
                  <img src="/images/adm-img2.png" alt="" />
                  <h4>Local store </h4>
                </div>
                <div className="decp">
                  <p>
                    Reach potential customers in your area on demand by
                    advertising your local store.
                  </p>
                  <a href="/create-campaign" className cret-camp-btn>
                    Create Campaign
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="wrap">
                <div className="img-wrap">
                  <img src="/images/adm-img3.png" alt="" />
                  <h4>Online shop </h4>
                </div>
                <div className="decp">
                  <p>
                    Showcase your products to a local or global audience and
                    maximize online sales.
                  </p>
                  <a href="/create-campaign" className cret-camp-btn>
                    Create Campaign
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h2>All campaigns</h2>
              <div className="table-wrap">
                <table>
                  <tbody>
                    <tr>
                      <th>Campaign</th>
                      <th>Demographics </th>
                      <th>Gender</th>
                      <th>Age group</th>
                      <th>Action</th>
                      <th>Delete</th>
                    </tr>
                    {campaigns?.map((campaign) => {
                      const handleRemoveCampaign = (id) => {
                        RemoveCampaign(id);
                      };

                      return (
                        <tr key={campaign._id}>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/campaign-performance/?id=${campaign._id}`
                              )
                            }
                          >
                            {campaign.image ? (
                              <img
                                style={{
                                  height: "4.2rem",
                                  width: "4.6rem",
                                  borderRadius: "10%",
                                }}
                                src={campaign.image}
                                alt=""
                              />
                            ) : (
                              <img
                                style={{
                                  height: "4.2rem",
                                  width: "4.6rem",
                                  borderRadius: "10%",
                                }}
                                src="/images/camp-list-img2.svg"
                                alt=""
                              />
                            )}
                            <h5>{campaign.campaign_title}</h5>
                            <p>
                              {moment(campaign.start_date).format(`DD MMMM`)} to{" "}
                              {campaign.end_date
                                ? moment(campaign.end_date).format(`DD MMMM`)
                                : "Forever"}
                            </p>
                          </td>

                          <td style={{ width: "3rem" }}>All user</td>
                          <td>{campaign.gender}</td>
                          <td>
                            {campaign.start_age}-{campaign.end_age}
                          </td>
                          <td style={{ zIndex: "10000" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "`100%",
                              }}
                            >
                              <Link
                                to="/edit-campaign"
                                state={{ Campaign: campaign }}
                                className="edit-btn"
                              >
                                <i className="fa-solid fa-pencil" />
                              </Link>
                            </div>
                          </td>
                          <td style={{ zIndex: "10000" }}>
                            <Link
                              to=""
                              data-toggle="modal"
                              data-bs-toggle="modal"
                              // data-bs-target="#myModal"
                              data-bs-target={`#myModal${campaign._id}`}

                              style={{ background: "transparent" }}
                              className="del-btn"
                            >
                              <img src="/images/camp-list-img3.svg" alt="" />
                            </Link>

                            <div
                              class="modal fade confirmed-modal"
                              id={`myModal${campaign._id}`}
                              // id="myModal"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="myModal"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <h3>
                                      are you sure you would like to delete your
                                      ad?
                                    </h3>
                                  </div>

                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      data-bs-dismiss="modal"
                                      onClick={() => {
                                        handleRemoveCampaign(campaign._id);
                                      }}
                                    >
                                      Yes{" "}
                                    </button>
                                    <button
                                      type="button"
                                      data-bs-dismiss="modal"
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AdManager;
