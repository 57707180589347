import React from 'react';
import '../css/style.css';
import '../css/style.css.map';
import '../css/style.scss';
import '../css/responsive.css';
import '../css/bootstrap.min.css';
import '../css/slimNav_sk78.css';

function Footer() {
  return (
    <>
    <footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <p>Copyright 2023 MapMetrics </p>
            </div>
            <div class="col-lg-6 col-md-6 text-end">
                <a href="https://mapmetrics.org/terms-of-service/" target="_blank" rel="noopener noreferrer"> Terms of Service</a>
            </div>
        </div>
    </div>
    </footer>
    </>
  )
}

export default Footer