import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const DonutChart = (props) => {
    const [options] = useState({
        chart: {
            type: 'donut',
            width: '100%'
        },
        labels: ['Impr Road', 'Clicks Wallet', 'Impr Wallet', 'Clicks Link'],
        series: [props.impr_road, props.clicks_wallet, props.impr_wallet, props.clicks_link],
        colors: [ '#DF5D5D', '#53D6B7', '#F8B14F', '#50DB66'],
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 500,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]

    });

    return (
        <div id="chart">
            <ReactApexChart options={options} series={options.series} type="donut" width={350} />
        </div>
    );
}

export default DonutChart;