import React, {useState, useEffect} from 'react';
import '../css/style.css';
import '../css/style.css.map';
import '../css/style.scss';
import '../css/responsive.css';
import '../css/bootstrap.min.css';
import '../css/slimNav_sk78.css';
import { Link, useLocation } from 'react-router-dom';

function Header() {

  const location = useLocation();

  const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User"));

  const SuperAdmin = JSON.parse(localStorage.getItem("SuperAdmin")) || JSON.parse(sessionStorage.getItem("SuperAdmin"));


  const [active, setActive] = useState(false);

  useEffect(() => {
    if (active) {
      document.body.className = "open-menu";
    } else {
      document.body.className = "";
    }
  }, [active]);


 

  return (
    <>
  {User || SuperAdmin?
  
        <header>
  <div className="container-fluid">
    <div className="row align-items-center">
      <div className="col-lg-6 col-5">
        {User?
        <Link to="/" className="logo"><img src="/images/logo.svg" alt="" /></Link>
        :
        SuperAdmin&& <Link to="/super-admin/dashboard" className="logo"><img src="/images/logo.svg" alt="" /></Link>
        }
      </div>
      <div className="col-lg-6 col-7 text-end">
                <ul>
                  <li><a href="#" className='bom-menu-icon' onClick={() => setActive(!active)}> <div id="hamburger" className={active ? "open" : ""}>
		<svg width="100" height="100" viewBox="0 0 100 100">
			<path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
			<path class="line line2" d="M 20,50 H 80" />
			<path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
		</svg>
	</div></a></li>
          {User?
          <li> 
          <a href="/profile" className="user-drop" style={{textDecoration: "none"}}>
         {User && User.profileImg? <img src={User.profileImg} alt="" style={{height:"2.5rem", width:"2.5rem", objectFit:"cover"}} /> : null }<span>{User? `${User.first_name} ${User.last_name}` : SuperAdmin? SuperAdmin.name : ""}<img src={"/images/down-arrow.svg"} alt="" /></span>
        </a>
        </li>
        :
        SuperAdmin&&  
        <Link to="" className="user-drop" style={{textDecoration: "none"}}>
         <span>{SuperAdmin&& SuperAdmin.name}</span>
        </Link>
        }
        </ul>
        
       
      </div>
    </div>
  </div>
  </header>
  :
     <header>
       <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <a href="#" class="logo"><img src="/images/logo.svg" alt="" /></a>
                </div>
            </div>
       </div>
    </header>

}
    </>
  )
}

export default Header