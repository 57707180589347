import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminSidebar() {

    const location = useLocation();

    const navigate = useNavigate();

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }

    const logOut = async (e) => {
        e.preventDefault();
        await Axios.get(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/logout`,{headers : {jwt : token}})
    .then((res) => {
      console.log(res.data);
      localStorage.clear();
      sessionStorage.clear();
    }).finally(() => {
        navigate('/login');
    }).catch((e) => {
      if(e.response.status == 401){
        Error(e.response.data.message);
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
      }
        console.log(e.response.data);
        Error(e.response.data.message);
    })
      }

  return (
    <>
    <ToastContainer autoClose={1000} />
    <section className="menu-sec">
     <ul>
       <li className={location.pathname==="/super-admin/dashboard"? "active" : location.pathname === "/company-details"? "active" : ""}><Link to="/super-admin/dashboard"> <img src="/images/menu-icon6.png" alt="" /> Company list </Link></li>
       <li className={location.pathname==="/super-admin/screening"? "active" : location.pathname === "/edit-campaign"? "active" : ""}><Link to="/super-admin/screening"> <img src="/images/menu-icon7.png" alt="" /> Ad Screening </Link></li>
     </ul>
     <Link onClick={logOut} className="log-out"> <img src="/images/menu-icon5.svg" alt="" /> LogOut</Link>
    </section>
    </>
  )
}

export default AdminSidebar