import React, { useRef, useState } from 'react';
import Header from '../Components/Header';
// import '../css/style.css';
// import '../css/style.css.map';
// import '../css/style.scss';
// import '../css/responsive.css';
// import '../css/bootstrap.min.css';
// import '../css/slimNav_sk78.css';
import Footer from '../Components/Footer';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function Login() {
  
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");

  const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User"));

  const [userLogin, setUserLogin] = useState({
    logger : "",
    password : ""
  })

  const [remember, setRemember] = useState(false);
 

  let name, value;

  const inputsHandler = (e) => {
    name = e.target.name;
    value = e.target.value;

    setUserLogin({...userLogin, [name] : value});

    if(e.target.checked){
      setRemember(true);
    }else{
      setRemember(false);
    }
  }

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }
  

  const submitHandler = async (e) => {
    e.preventDefault();
    if(/@mapmetrics.com\s*$/.test(userLogin.logger) === false && /@mapmetrics.org\s*$/.test(userLogin.logger) === false){
    await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/user/login`,userLogin)
    .then((res) => {
      console.log(res.data);
      if(remember){
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("User", JSON.stringify(res.data.User));
      }else{
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("User", JSON.stringify(res.data.User));
      }
      if(res.data.User){
       const token = res.data.token;
       Axios.get(`https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/get-campaigns-by-user/${res.data.User._id}`,{headers : {jwt : token}})
        .then((res) => {
            console.log(res.data);
            if(res.data.Campaigns.length === 0){
              navigate('/ad-manager')
            }else{
              navigate('/');
            }
        }).catch((e) => {
          if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })
     
    }
    }).catch((e) => {
      console.log(e);
      Error(e.response.data.message);
    })
  }else{
    await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/login`,
    {email : userLogin.logger, password : userLogin.password},
    {withCredentials: true})
    .then((res) => {
      console.log(res.data);
      if(remember){
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("SuperAdmin", JSON.stringify(res.data.SuperAdmin));
      }else{
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("SuperAdmin", JSON.stringify(res.data.SuperAdmin));
      }
      navigate('/super-admin/dashboard');
    }).catch((e) => {
      if(e.response.status == 401){
        Error(e.response.data.message);
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
      }
        console.log(e.response.data);
        Error(e.response.data.message);
    })
  }
}



  return (
    <>
    {isAuthenticated && User?
    <Navigate to="/" />
    :
    <>
      <Helmet>
        <meta name="robots" content="index,follow" />
      </Helmet>
    <Header />
    <ToastContainer autoClose={1000} />
    <section className="login-page">
  <img src="images/logo2.svg" alt="" className="img1" />
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-6">
        <h2>Login </h2>
        <h6>New to MapMetrics? <Link to="/create-account">Sign up for free</Link></h6>
        <form onSubmit={submitHandler}>
          <label htmlFor>Username or Email</label>
          <input type="text" name="logger" value={userLogin.logger} onChange={inputsHandler} placeholder="Enter your username or email address" />
          <label htmlFor>Password</label>
          <input type="password" name="password" value={userLogin.password} onChange={inputsHandler} placeholder="Enter your password" />
          <div className="rember-sec">
            <label> <input type="checkbox" name id onChange={inputsHandler} /> Remember me </label>
            <a href="/forgot-password" className="fgt-pass">Forgot password?</a>
          </div>
          <input type="submit" value="Login" />
        </form>
      </div>
    </div>
  </div>
</section>
<Footer />
</>
}
</>
  )
}

export default Login