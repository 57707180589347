import React, { useRef, useState } from 'react';
import Header from '../Components/Header';
// import '../css/style.css';
// import '../css/style.css.map';
// import '../css/style.scss';
// import '../css/responsive.css';
// import '../css/bootstrap.min.css';
// import '../css/slimNav_sk78.css';
import Footer from '../Components/Footer';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function ForgotPassword() {
  
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");

    const [ email, setEmail ] = useState("");

    const Error = (message) => {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

      const Success = (message) => {
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

    const sendEmail = async (e) => {
        e.preventDefault()
        await Axios.post('https://he2wkhrdue.eu-central-1.awsapprunner.com/user/forgot-password',{email})
        .then((res) => {
            console.log(res.data);
            Success(res.data.message);
        }).catch((e) => {
            Error(e.response.data.message);
        })
    }

  return (
    <>
    {isAuthenticated?
    <Navigate to="/" />
    :
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet> 
    <Header />
    <ToastContainer autoClose={1000} />
    <section className="login-page">
  <img src="images/logo2.svg" alt="" className="img1" />
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-6">
        <h2>Forgot Password </h2>
        <h6>New to MapMetrics? <Link to="/create-account">Sign up for free</Link></h6>
        <form>
          <label htmlFor>Email Address</label>
          <input required type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your username or email address" />

          <input style={{marginTop:"0.25rem",cursor: "pointer"}} disabled={email? false : true} onClick={sendEmail} type="submit" value="Send Password Reset Link" />
        </form>
      </div>
    </div>
  </div>
</section>
<Footer />
</>
}
</>
  )
}

export default ForgotPassword