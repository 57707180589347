import React, { useState } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import Axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Payment() {

    const navigate = useNavigate();

    const [amount, setAmount] = useState("");

    const [payment, setPayment] = useState("");

    const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");

    const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User"));

    const CreatePayment = async (e) => {
      e.preventDefault();
      const data = { 
        email: User.email, 
        user_id: User._id, 
        amount: amount,
        affiliate_code : User.affiliate_code
     }
      await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/payment/create-payment`,data,{headers : {jwt : isAuthenticated}})
      .then((res) => {
        console.log(res.data);
        setPayment(res.data.Payment);
        window.open(`http://mapmetrics.org/cart/?id=${res.data.Payment._id}`,'_blank');
      }).catch((e) => {
        console.log(e);
      })
    }

  return (
    <>
    {isAuthenticated && User?
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>
    <Header />
    <section className="payment-page">
  <Sidebar />
  <div className="page-decp-dec">
    <div className="row">
      <div className="col-lg-12">
        <div className="wrap">
          <h3>Payment</h3>
          <p> Please input the quantity of USD you want to convert to mAds. The exchange rate is 1 USD to 1 mAds.</p>
          <div className="card">
            <div className="accordion" id="accordionExample">
              <div className="card">
                {/* <div className="card-header">
                  <h2>
                    <button data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>Credit card</span>
                      <div className="icons">
                        <img src="https://i.imgur.com/2ISgYja.png" width={30} />
                        <img src="https://i.imgur.com/W1vtnOV.png" width={30} />
                        <img src="https://i.imgur.com/35tC99g.png" width={30} />
                        <img src="https://i.imgur.com/2ISgYja.png" width={30} />
                      </div>
                    </button>
                  </h2>
                </div> */}
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body payment-card-body">
                    <span className="font-weight-normal card-text">Enter Amount</span>
                    <div className="input">
                      <i className="fa fa-credit-card" />
                      <input onChange={(e) => setAmount(e.target.value)} type="text" className="form-control" placeholder="1000" />
                    </div>
                    {/* <div className="row mt-3 mb-3">
                      <div className="col-md-6">
                        <span className="font-weight-normal card-text">Expiry Date</span>
                        <div className="input">
                          <i className="fa fa-calendar" />
                          <input type="text" className="form-control" placeholder="MM/YY" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <span className="font-weight-normal card-text">CVC/CVV</span>
                        <div className="input">
                          <i className="fa fa-lock" />
                          <input type="text" className="form-control" placeholder="000" />
                        </div>
                      </div>
                    </div> */}
                    <span className="text-muted certificate-text"><i className="fa fa-lock" /> Your
                      transaction is secured with ssl certificate</span>
                  </div>
                </div>
              </div>
              <div style={{display:"flex", width:"100%",justifyContent:"center",marginTop:"0.5rem"}}>
                 <input type="submit" onClick={CreatePayment} className='submit-payment' value="Apply" />
              </div>
              {/* <div className="card">
                <div className="card-header" id="headingTwo">
                  <h2>
                    <button type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>Paypal</span>
                      <img src="https://i.imgur.com/7kQEsHU.png" width={30} />
                    </button>
                  </h2>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div className="card-body">
                    <input type="text" className="form-control" placeholder="Paypal email" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-lg-6">
        <div className="wrap">
          <h3>Summary</h3>
          <form action className="code-sec">
            <input type="text" placeholder="Discount Code" />
            <input type="submit" defaultValue="Apply" />
          </form>
          <table>
            <tbody><tr>
                <th>Subtotal</th>
                <td>$200</td>
              </tr>
              <tr>
                <th>Shipping</th>
                <td>$200</td>
              </tr>
              <tr>
                <th>Total</th>
                <td>$400</td>
              </tr>
            </tbody></table>
        </div>
      </div> */}
    </div>
  </div>
</section>
    <Footer />
    </>
      :
      <Navigate to="/login" />
     }
     </>
  )
}

export default Payment