import React, { useState } from "react";
import Header from "../Components/Header";
// import '../css/style.css';
// import '../css/style.css.map';
// import '../css/style.scss';
// import '../css/responsive.css';
// import '../css/bootstrap.min.css';
// import '../css/slimNav_sk78.css';
import Footer from "../Components/Footer";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Axios from "axios";
import Sidebar from "../Components/Sidebar";
import Loader from "../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function Register() {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("id"));

  const navigate = useNavigate();

  const user_id = searchParams.get("id");

  const User =
    JSON.parse(localStorage.getItem("User")) ||
    JSON.parse(sessionStorage.getItem("User"));

  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    first_name: User?.first_name,
    last_name: User?.last_name,
    company_name: User?.company_name,
    address_line1: User?.address_line1,
    address_line2: User?.address_line2,
    address_line3: User?.address_line3,
    city: User?.city,
    state: User?.state,
    pincode: User?.pincode,
    country: User?.country,
    commerce_chamber: User?.commerce_chamber,
    vat_number: User?.vat_number,
    country: User?.country,
    affiliate_code: User?.affiliate_code,
    profileImg: User?.profileImg,
  });

  const hiddenFileInput = React.useRef(null);

  const [file, setFile] = useState("");

  const uploadProfilePic = async (file) => {
    setLoading(true);
    if (file.size > 1048576) {
      setLoading(false);
      setFile("");
      return Error("File size too big !");
    }
    const url = `https://he2wkhrdue.eu-central-1.awsapprunner.com/upload`;
    const formData = new FormData();
    formData.append("file", file);
    await Axios.post(url, formData)
      .then((response) => {
        console.log(response);
        setUser({ ...user, profileImg: response.data.fileUrl });
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const fileUpload = (e) => {
    e.preventDefault();
    console.log(e);
    setFile(e.target.files[0]);
    uploadProfilePic(e.target.files[0]);
    e.target.value = null;
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setFile(file);
    if (e.dataTransfer.files[0]) {
      uploadProfilePic(e.dataTransfer.files[0]);
    }
  };

  console.log(file);

  let name, value;

  const inputsHandler = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Success = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  let Affiliate = "";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (user.affiliate_code) {
      
      await Axios.post(
        // `https://d18318ocrg0sph.cloudfront.net/validate-affiliate`,
        `https://pdeuwudkyh.eu-central-1.awsapprunner.com/validate-affiliate`,
        { affiliate_code: user.affiliate_code }
      )
        .then((res) => {
          console.log(res.data);
          Affiliate = true;
        })
        .finally(() => {
          if (
            (user.affiliate_code && Affiliate === true) ||
            user.affiliate_code === ""
          ) {
            Axios.post(
              `https://he2wkhrdue.eu-central-1.awsapprunner.com/user/update-user/${user_id}`,
              user
            )
              .then((res) => {
                console.log(res.data);
                if (location.pathname == "/edit-profile/") {
                  if (sessionStorage.getItem("User")) {
                    sessionStorage.setItem(
                      "User",
                      JSON.stringify(res.data.UpdatedUser)
                    );
                  } else if (localStorage.getItem("User")) {
                    localStorage.setItem(
                      "User",
                      JSON.stringify(res.data.UpdatedUser)
                    );
                  }
                  navigate("/profile");
                }
                Axios.post(
                  `https://he2wkhrdue.eu-central-1.awsapprunner.com/user/confirm-email`,
                  { id: user_id }
                )
                  .then((res) => {
                    console.log(res.data);
                    if (localStorage.getItem("token")) {
                      localStorage.setItem(
                        "User",
                        JSON.stringify(res.data.UpdatedUser)
                      );
                    } else if (sessionStorage.getItem("token")) {
                      sessionStorage.setItem(
                        "User",
                        JSON.stringify(res.data.UpdatedUser)
                      );
                    }
                    Success(res.data.message);
                  })
                  .finally(() => {
                    navigate("/review-business-details");
                  })
                  .catch((e) => {
                    if (e.response.status == 401) {
                      Error(e.response.data.message);
                      localStorage.clear();
                      sessionStorage.clear();
                      navigate("/login");
                    }
                    console.log(e.response.data);
                    // Error(e.response.data.message);
                  });
              })
              .catch((e) => {
                if (e.response.status == 401) {
                  Error(e.response.data.message);
                  localStorage.clear();
                  sessionStorage.clear();
                  navigate("/login");
                }
                console.log(e.response.data);
                Error(e.response.data.message);
              });
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          }
          console.log(e.response.data);
          Error(e.response.data.message);
          Affiliate = false;
        });
    } else {
      await Axios.post(
        `https://he2wkhrdue.eu-central-1.awsapprunner.com/user/update-user/${user_id}`,
        user,
        { headers: { jwt: isAuthenticated } }
      )
        .then((res) => {
          console.log(res.data);
          if (location.pathname == "/edit-profile/") {
            if (sessionStorage.getItem("User")) {
              sessionStorage.setItem(
                "User",
                JSON.stringify(res.data.UpdatedUser)
              );
            } else if (localStorage.getItem("User")) {
              localStorage.setItem(
                "User",
                JSON.stringify(res.data.UpdatedUser)
              );
            }
            navigate("/profile");
          }
          Axios.post(
            `https://he2wkhrdue.eu-central-1.awsapprunner.com/user/confirm-email`,
            { id: user_id },
            { headers: { jwt: isAuthenticated } }
          )
            .then((res) => {
              console.log(res.data);
              if (localStorage.getItem("token")) {
                localStorage.setItem(
                  "User",
                  JSON.stringify(res.data.UpdatedUser)
                );
              } else if (sessionStorage.getItem("token")) {
                sessionStorage.setItem(
                  "User",
                  JSON.stringify(res.data.UpdatedUser)
                );
              }
              Success(res.data.message);
            })
            .finally(() => {
              navigate("/review-business-details");
            })
            .catch((e) => {
              if (e.response.status == 401) {
                Error(e.response.data.message);
                localStorage.clear();
                sessionStorage.clear();
                navigate("/login");
              }
              console.log(e.response.data);
              // Error(e.response.data.message);
            });
        })
        .catch((e) => {
          if (e.response.status == 401) {
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          }
          console.log(e.response.data);
          Error(e.response.data.message);
        });
    }
  };

  console.log(loading);

  const isAuthenticated =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const location = useLocation();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <Header />
      <ToastContainer autoClose={1000} />
      <section
        className={
          location.pathname === "/edit-profile/"
            ? "create-acc-page menu"
            : "create-acc-page menu-inactive"
        }
      >
        <Sidebar />
        <div className="page-decp-dec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Business details </h2>
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3>Personal Info</h3>
                      <label htmlFor>
                        First name <span>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="first_name"
                        value={user.first_name}
                        onChange={inputsHandler}
                        placeholder="Enter your first name"
                      />
                      <label htmlFor>
                        Last name <span>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="last_name"
                        value={user.last_name}
                        onChange={inputsHandler}
                        placeholder="Enter your last name"
                      />
                      <h3>Company Details</h3>
                      <label htmlFor>
                        Company name <span>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="company_name"
                        value={user.company_name}
                        onChange={inputsHandler}
                        placeholder="Enter your Company name"
                      />
                      <label htmlFor>
                        Address Line 1 <span>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="address_line1"
                        value={user.address_line1}
                        onChange={inputsHandler}
                        placeholder="Enter your Company address"
                      />
                      <label htmlFor> Address Line 2 </label>
                      <input
                        type="text"
                        name="address_line2"
                        value={user.address_line2}
                        onChange={inputsHandler}
                        placeholder="Enter your Company address"
                      />
                      <label htmlFor>Address Line 3 </label>
                      <input
                        type="text"
                        name="address_line3"
                        value={user.address_line3}
                        onChange={inputsHandler}
                        placeholder="Enter your Company address"
                      />
                      <label htmlFor>
                        City <span>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="city"
                        value={user.city}
                        onChange={inputsHandler}
                        placeholder="Enter your City"
                      />
                      <label htmlFor>
                        State/Province <span>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="state"
                        value={user.state}
                        onChange={inputsHandler}
                        placeholder="Enter your State"
                      />
                      <label htmlFor>
                        Postal Code <span>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="pincode"
                        value={user.pincode}
                        onChange={inputsHandler}
                        placeholder="Enter your Postal Code"
                      />

                      <label htmlFor>
                        Country business <span>*</span>
                      </label>
                      <select
                        id="country"
                        name="country"
                        required
                        onChange={inputsHandler}
                        value={user.country}
                      >
                        <option>Select your Country</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Aland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua & Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire, Sint Eustatius and Saba">
                          Caribbean Netherlands
                        </option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia & Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo - Brazzaville</option>
                        <option value="Congo, Democratic Republic of the Congo">
                          Congo - Kinshasa
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'Ivoire">Côte d’Ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curacao">Curaçao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czechia</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Islas Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard & McDonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Vatican City
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          North Korea
                        </option>
                        <option value="Korea, Republic of">South Korea</option>
                        <option value="Kosovo">Kosovo</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Laos
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, the Former Yugoslav Republic of">
                          North Macedonia
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia
                        </option>
                        <option value="Moldova, Republic of">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar (Burma)</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Curaçao</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestine
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn Islands</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Réunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Barthelemy">St. Barthélemy</option>
                        <option value="Saint Helena">St. Helena</option>
                        <option value="Saint Kitts and Nevis">
                          St. Kitts & Nevis
                        </option>
                        <option value="Saint Lucia">St. Lucia</option>
                        <option value="Saint Martin">St. Martin</option>
                        <option value="Saint Pierre and Miquelon">
                          St. Pierre & Miquelon
                        </option>
                        <option value="Saint Vincent and the Grenadines">
                          St. Vincent & Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          São Tomé & Príncipe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Serbia and Montenegro">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Sint Maarten">Sint Maarten</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and the South Sandwich Islands">
                          South Georgia & South Sandwich Islands
                        </option>
                        <option value="South Sudan">South Sudan</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard & Jan Mayen
                        </option>
                        <option value="Swaziland">Eswatini</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syria</option>
                        <option value="Taiwan, Province of China">
                          Taiwan
                        </option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-Leste">Timor-Leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad & Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks & Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          U.S. Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Vietnam</option>
                        <option value="Virgin Islands, British">
                          British Virgin Islands
                        </option>
                        <option value="Virgin Islands, U.s.">
                          U.S. Virgin Islands
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis & Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>

                      <input type="submit" value="Save" />
                    </div>
                    <div className="col-lg-6">
                      <h3>Tax info</h3>
                      <label htmlFor>Chamber of commerce</label>
                      <input
                        type="text"
                        name="commerce_chamber"
                        value={user.commerce_chamber}
                        onChange={inputsHandler}
                        placeholder="Enter your Chamber of commerce"
                      />
                      <label htmlFor>VAT number</label>
                      <input
                        type="text"
                        name="vat_number"
                        value={user.vat_number}
                        onChange={inputsHandler}
                        placeholder="Enter your VAT number"
                      />
                      <h5>Upload logo</h5>

                      {loading ? (
                        <Loader />
                      ) : file ? (
                        <>
                          <h6
                            style={{ color: "#00A69F", fontWeight: "bolder" }}
                          >
                            {file.name}
                            <i
                              style={{
                                cursor: "pointer",
                                marginLeft: "0.35rem",
                                marginTop: "0.35rem",
                              }}
                              onClick={() => {
                                setFile("");
                                setUser({ ...user, profileImg: "" });
                              }}
                              className="fa-solid fa-times"
                            ></i>
                          </h6>
                        </>
                      ) : user.profileImg ? (
                        <h6 style={{ color: "#00A69F", fontWeight: "bolder" }}>
                          {user.profileImg.substring(
                            user.profileImg.lastIndexOf("/") + 1,
                            user.profileImg.length
                          )}
                        </h6>
                      ) : null}

                      <div
                        className="img-up-sec"
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => e.preventDefault()}
                        onDragLeave={(e) => e.preventDefault()}
                        onDrop={fileDrop}
                      >
                        <img src="images/img-up-icon.svg" alt="" />
                        <h5>Drag &amp; Drop</h5>
                        <h6>
                          Your files here or Browse to upload{" "}
                          <span>Only JPEG and PNG with max size 1MB </span>
                        </h6>
                        <input
                          accept="image/png, image/jpeg"
                          type="file"
                          onChange={(e) => fileUpload(e)}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                        {file ? (
                          <>
                            {loading ? (
                              <Loader />
                            ) : (
                              <button className="upload-button" disabled>
                                Uploaded
                              </button>
                            )}
                          </>
                        ) : (
                          <Link to="" onClick={handleClick}>
                            Upload
                          </Link>
                        )}
                      </div>

                      <label htmlFor>Affiliate Code</label>
                      <input
                        type="text"
                        name="affiliate_code"
                        value={user.affiliate_code}
                        onChange={inputsHandler}
                        placeholder="Enter Your Affiliate Code"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Register;
