import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import Axios from 'axios';
import { getCampaignStatsMonthly } from '../pages/Dashboard';


const areaChartOptions = {
    chart: {
        height: 450,
        type: 'area',
        toolbar: {
            show: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth',
        width: 5
    },
    grid: {
        strokeDashArray: 0
    }
};

const CampaignChartYear = ({ slot, impr_road, impr_wallet, clicks_link, clicks_wallet, setType,type, }) => {

    const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User"));

    const theme = useTheme();
    const { primary, secondary } = theme.palette.text;
    const line = theme.palette.divider;

    // const [month, setMonth] = useState("");

    const [options, setOptions] = useState(areaChartOptions);

    // const [month, setMonth] = useState("");

   let month;

    useEffect(() => {

        setOptions((prevState) => ({
            ...prevState,
            colors: ['#DF5D5D', '#F8B14F', '#53D6B7' , '#50DB66'],
            dataLabels: {
                enabled: false
              },
              legend: {
                show: true
              },

              chart: {
                events: {
                  xAxisLabelClick: function(event, chartContext, config) {
                  console.log(event.target.innerHTML);
                  setType('month');
                  if(event.target.innerHTML == "Jan"){
                     month = 0;
                  }else if(event.target.innerHTML == "Feb"){
                    month = 1;
                  }
                  else if(event.target.innerHTML == "Mar"){
                    month = 2;
                  }
                  else if(event.target.innerHTML == "Apr"){
                    month = 3;
                  }
                  else if(event.target.innerHTML == "May"){
                    month = 4;
                  }
                  else if(event.target.innerHTML == "Jun"){
                    month = 5;
                  }
                  else if(event.target.innerHTML == "Jul"){
                    month = 6;
                  }
                  else if(event.target.innerHTML == "Aug"){
                    month = 7;
                  }
                  else if(event.target.innerHTML == "Sep"){
                    month = 8;
                  }
                  else if(event.target.innerHTML == "Oct"){
                    month = 9;
                  }
                  else if(event.target.innerHTML == "Nov"){
                    month = 10;
                  }
                  else if(event.target.innerHTML == "Dec"){
                    month = 11;
                  }
                  getCampaignStatsMonthly('month',month);
                }
               }
              },
            xaxis: {
                categories:
                    ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                // : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                labels: {
                    style: {
                        colors: [
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary
                        ],
                        cursor: 'pointer',
                    }
                },
                axisBorder: {
                    show: true,
                    color: line
                },
            

            },
            yaxis: {
                labels: {
                    style: {
                        colors: [secondary]
                    }
                }
            },
            grid: {
                borderColor: line
            },
            tooltip: {
                theme: 'light'
            }
        }));
    }, [primary, secondary, line, theme, slot]);

    const [series, setSeries] = useState([
        {
            name: 'Road Impressions',
            data: impr_road
        },
        {
            name: 'Wallet Impression',
            data: impr_wallet
        },
        {
            name: 'Wallet Clicks',
            data: clicks_wallet
        },
        {
            name: 'Link Clicks',
            data: clicks_link
        }

    ]);

    useEffect(() => {
        setSeries([
            {
                name: 'Impressions Road',
                data: impr_road
            },
            {
                name: 'Impressions Wallet',
                data: impr_wallet
            },
            {
                name: 'Clicks Wallet',
                data: clicks_wallet
            },
            {
                name: 'Clicks Link',
                data: clicks_link
            }

        ]);
    }, []);


    
    return (
        <>

            <div className="App">
                {series&&
                <ReactApexChart options={options} legend={false} series={series? series : null} type="line" height={450} />
                }
            </div>
        
        </>
    )
}

CampaignChartYear.propTypes = {
    slot: PropTypes.string
};

export default CampaignChartYear







