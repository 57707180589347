import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function Register() {

    const navigate = useNavigate();
    
    const [status, setStatus] = useState(true);

    const [user, setUser] = useState({
        username : "",
        email : "",
        password : "",
        cpassword : ""
    })

    let name, value;

    const inputsHandler = (e) => {
        name = e.target.name;
        value = e.target.value;

     setUser({...user, [name] : value})

     if(e.target.checked){
        setStatus(false);
     }else{
        setStatus(true);
     }
    }

    const Success = (message) => {
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    const Error = (message) => {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
    

    const submitHandler = async (e) => {
      e.preventDefault();
      if(!status){
        await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/user/register`,user,{withCredentials: true})
        .then((res) => {
            console.log(res.data);
            localStorage.setItem("token",res.data.token);
            // localStorage.setItem("User", JSON.stringify(res.data.User));
            // Success(res.data.message);
             Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/user/send-confirmation-email`,{email : res.data.User.email})
             .then((res) => {
              console.log(res.data);
              Success(res.data.message);
             }).finally(() => {
              navigate('/confirm-email');
             }).catch((e) => {
              Error(e.response.data.message);
             })
            // navigate(`/business-registration/?id=${res.data.User._id}`);
        }).catch((e) => {
          Error(e.response.data.message);
        })
      }else{
        Error("Please Check the Terms of Service box!")
      }
    }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
     </Helmet> 
    <Header />
    <ToastContainer autoClose={1000} />
    <section className="login-page">
  <img src="/images/logo2.svg" alt="" className="img1" />
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-6">
        <h2>Create a MapMetrics Account </h2>
        <h6>Already have a user account? <Link to="/login">Login</Link></h6>
        <form onSubmit={submitHandler}>
          <label htmlFor>Username</label>
          <input type="text" required placeholder="Enter your username"  name="username"  value={user.username} onChange={inputsHandler} />
          <label htmlFor>Business email</label>
          <input type="text" required placeholder="Enter your email address"  name="email"  value={user.email} onChange={inputsHandler} />
          <label htmlFor>Password</label>
          <input type="password" required placeholder="Please Enter your Password" name="password"  value={user.password} onChange={inputsHandler}  />
          <label htmlFor>Confirm Password</label>
          <input type="password" required placeholder="Enter your password again"  name="cpassword" value={user.cpassword} onChange={inputsHandler}  />
          <div className="rember-sec">
            <label> <input type="checkbox" onChange={inputsHandler} name id /> By clicking here, I state that I have read and
              understood the <a href="https://mapmetrics.org/terms-of-service/" target="_blank" rel="noopener noreferrer">terms of service.</a> </label>
          </div>
          <input type="submit" value="Sign up" />
        </form>
      </div>
    </div>
  </div>
</section>
<Footer />
    </>
  )
}

export default Register