import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import AdminSidebar from '../Components/AdminSidebar';

function CompanyDetails() {

  const navigate = useNavigate();

  const location = useLocation();

  const CompanyID = location.state.company_id;

    const [user, setUser] = useState("")

    const SuperAdmin = JSON.parse(localStorage.getItem("SuperAdmin")) || JSON.parse(sessionStorage.getItem("SuperAdmin"));

    const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");

    const Error = (message) => {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    const fetchCompany = async () => {
        await Axios.get(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/get-company/${CompanyID}`, {headers : {jwt : isAuthenticated}})
        .then((res) => {
            console.log(res.data);
            setUser(res.data.User);
        }).catch((e) => {
          if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })
    }


    useEffect(() => {
     fetchCompany();
    }, [])
    

  console.log(SuperAdmin);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet> 
    <Header />
    <ToastContainer autoClose={1000} />
    <section className="business-details-page menu">
    <AdminSidebar  />   
  <div className="page-decp-dec">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {user.profileImg? <img src={user.profileImg} style={{maxWidth:"15rem", maxHeight:"15rem", borederRadius:"50%"}} className="pro-img" />  : null}
        </div>
        <div className="col-lg-3">
          <h3>Personal Info</h3>
          <ul className="decp">
            <li>
              <h4>First name</h4>
              <p style={{ textTransform: "capitalize" }}>{user.first_name}</p>
            </li>
            <li>
              <h4>Last name</h4>
              <p style={{ textTransform: "capitalize" }}>{user.last_name}</p>
            </li>
          </ul>
        </div>
        <div className="col-lg-9">
          <h3>Tax info</h3>
          <ul className="decp">
            <li>
              <h4>Chamber of commerce</h4>
              <p>{user.commerce_chamber}</p>
            </li>
            <li>
              <h4>VAT commerce</h4>
              <p>VAT {user.vat_number}</p>
            </li>
            <li>
              <h4>Affiliate Code</h4>
              <p>{user.affiliate_code}</p>
            </li>
          </ul>
        </div>
        <div className="col-lg-3">
          <h3>Company Details</h3>
          <ul className="decp">
            <li>
              <h4>Company name </h4>
              <p style={{ textTransform: "capitalize" }}>{user.company_name} </p>
            </li>
            <li>
              <h4>Address Line 1</h4>
              <p style={{ textTransform: "capitalize" }}>{user.address_line1}</p>
            </li>
            {user.address_line2&&
            <li>
              <h4>Address Line 2</h4>
              <p style={{ textTransform: "capitalize" }}>{user.address_line2}</p>
            </li>
            }
            {user.address_line3&&
            <li>
              <h4>Address Line 3</h4>
              <p style={{ textTransform: "capitalize" }}>{user.address_line3}</p>
            </li>
           }
           <li>
              <h4>City</h4>
              <p style={{ textTransform: "capitalize" }}>{user.city}</p>
            </li>
            <li>
              <h4>State</h4>
              <p style={{ textTransform: "capitalize" }}>{user.state}</p>
            </li>
            <li>
              <h4>Postal Code</h4>
              <p style={{ textTransform: "capitalize" }}>{user.pincode}</p>
            </li>
            <li>
              <h4>Country business</h4>
              <p style={{ textTransform: "capitalize" }}>{user.country}</p>
            </li>
          </ul>
        </div>
        <div className="col-lg-12">
              <div style={{display:"flex",width:"20rem", justifyContent:"space-between"}}>
              <Link to="/super-admin/screening"><input type="submit" value="Go Back" className="go-back-btn" /></Link>
              </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />
    </>
  )
}

export default CompanyDetails