import React, { useEffect, useState } from 'react'
import AdminSidebar from '../Components/AdminSidebar'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Axios from 'axios';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function Screening() {

    const isAuthenticated = localStorage.getItem("SuperAdmin") || sessionStorage.getItem("SuperAdmin");

    const token = localStorage.getItem('token') || sessionStorage.getItem('token');

    const navigate = useNavigate();

    const [ads, setAds] = useState([]);

    const [callApi, setCallApi] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const Error = (message) => {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    const fetchAds = async () => {
        await Axios.get('https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/get-all-campaigns')
        .then((res) => {
            console.log(res.data);
            setAds(res.data.Campaigns);
        }).catch((e) => {
          if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })
    }

    const approveCampaign = async (id) => {
        await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/change-campaign-status/${id}`,
        { status : "approved" }, 
        {headers : {jwt : token}})
        .then((res) => {
          console.log(res.data);
          setCallApi(!callApi);
        }).catch((e) => {
          if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })
      }
  
      const rejectCampaign = async (id) => {
        await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/change-campaign-status/${id}`,
        { status : "rejected" },
        {headers : {jwt : token}})
        .then((res) => {
          console.log(res.data);
          setCallApi(!callApi);
        }).catch((e) => {
          if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })
      }

      const RemoveCampaign = async (id) => {
        
        await Axios.delete(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/delete-campaign/${id}`,
        {headers : {jwt : token}})
        .then((res) => {
            console.log(res.data);
            setCallApi(!callApi);
        }).catch((e) => {
          if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })
    }



    useEffect(() => {
      fetchAds();
    }, [callApi])
    

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet> 
    <Header />
    <ToastContainer autoClose={1000} />
    <section className="screening-page">
  <AdminSidebar />
  <div className="page-decp-dec">
    <div className="row">
        {ads.map((ad) => {
            return (
                <div className="col-lg-4" key={ad._id} style={{marginBottom : "2rem"}}>
                <div className="wrap">
                  <div className="img-wrap" style={{position:"relative"}}>
                    {ad.image?
                    <img src={ad.image} style={{width:"10rem",height:"3.5rem",width:"3.5rem"}} alt="" />
                    :
                    <img src="/images/camp-list-img2.svg" style={{width:"10rem",height:"3.5rem",width:"3.5rem"}} alt="" />
                    }
                    <div className="decp1">
                      <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
                      <h2>{ad.campaign_title}</h2>
                      <Link to="" data-toggle="modal" data-bs-toggle="modal" data-bs-target={`#myModal${ad._id}`} style={{background:"transparent", top:"0", position:"absolute", right:"-1rem"}} className="del-btn"> <img src="/images/camp-list-img3.svg" alt="" /> </Link>

                          <div class="modal fade confirmed-modal"  id={`myModal${ad._id}`} tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
                               <div className="modal-dialog">
                                 <div className="modal-content">
                                   <div className="modal-body">
                                     <h3>are you sure you would like to delete your ad?</h3>
                                   </div>
                                   <div className="modal-footer">
                                      <button type="button"  data-bs-dismiss="modal" onClick={() => {RemoveCampaign(ad._id)}}  >Yes </button>
                                      <button type="button" data-bs-dismiss="modal">No</button>
                                   </div>
                                 </div>
                               </div>
                         </div>

                      </div> 
                      <h3>Status: 
                      <span style={ad.active === "active"? {color:"#3EF0B0"} : 
                        ad.active === "inactive"? {color:"#999999"} : 
                        ad.active === "pending"? {color : "#F8B14F"} : 
                        ad.active === "rejected" ? {color : "#F03E3E"} : {color : "#00A69F"}}  >
                          <i className="fa-solid fa-circle" /> {ad.active === "active"? 
                          "Active" : ad.active === "inactive" ? 
                          "Inactive" : ad.active === "pending"? 
                          "Pending" : ad.active === "rejected"? "Rejected" : "Approved"
                          }
                      </span>
                      </h3>
                      <Link to="/edit-campaign" state={{Campaign : ad}}>Ad Preview</Link>
                    </div>
                  </div>
                  <div className="decp2">
                    <h2>{ad.ad_title}</h2>
                    <p>{ad.ad_description}</p>
                    <ul>
                      <li><a href={`https://${ad.landing_url}`} target="_blank" rel="noreferrer"><i className="fa-solid fa-arrow-up-right-from-square" /> Visit now</a></li>
                      {/* <li><a href="#"><i className="fa-solid fa-pencil" /></a></li> */}
                    </ul>
                  </div>
                  <div className="decp3">
                    {ad&& ad.active !== "active"&&
                    <Link to="" onClick={() => approveCampaign(ad._id)}>Approve</Link>
                    }
                    <Link to="" onClick={() => rejectCampaign(ad._id)}>Decline</Link>
                  </div>
                </div>
              </div>

              
            )
        })}
    
      
    </div>
  </div>

</section>
<Footer />
    </>
  )
}

export default Screening