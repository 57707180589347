import React, { useState, version } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import Axios from 'axios';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from '../Components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Superscript } from 'react-bootstrap-icons';
import AdminSidebar from '../Components/AdminSidebar';
import { Helmet } from 'react-helmet';



function EditCampaign() {

    const navigate = useNavigate();

    const location = useLocation();

    const [loading,setLoading] = useState(false);

    const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");

    const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User")) ;

    const SuperAdmin = JSON.parse(localStorage.getItem("SuperAdmin")) || JSON.parse(sessionStorage.getItem("SuperAdmin")) ;

    const Campaign = location.state.Campaign;

    const [startAgeSelected, setStartAgeSelected] = useState(false);

    const [endAgeSelected, setEndAgeSelected] = useState(false);


    console.log(Campaign);

    const [campaign, setCampaign] = useState({
        start_age : Campaign?.start_age || 18,
        end_age : Campaign?.end_age || null,
        campaign_title : Campaign?.campaign_title || "",
        gender: Campaign?.gender || "",
        ad_title: Campaign?.ad_title || "",
        ad_description : Campaign?.ad_description || "",
        landing_url : Campaign?.landing_url || "",
        daily_budget : Campaign?.daily_budget || 0,
        start_date : Campaign?.start_date || "",
        end_date : Campaign?.end_date || "",
        image : Campaign?.image || "",
        active : Campaign?.active || ""
    })

    let name,value;

    const inputsHandler = (e) => {
      name = e.target.name;
      value = e.target.value;



      if(name === "daily_budget"){
        let budget = parseInt(value);
        setCampaign({...campaign, daily_budget : budget})
      }else{
        setCampaign({...campaign, [name] : value})
      }

  }


    const hiddenFileInput = React.useRef(null);

    const [file, setFile] = useState(""); 
   
       const uploadProfilePic = async (file) => {
         setLoading(true);
         if(file.size > 1048576){
          setLoading(false);
          setFile("");
           return Error('File size too big !')
         }
         const url = `https://he2wkhrdue.eu-central-1.awsapprunner.com/upload`;
         const formData = new FormData();
         formData.append('file',file);
         await Axios.post(url, formData).then((response) => {
           console.log(response);
           setCampaign({...campaign,image : response.data.fileUrl})
         }).finally(()=> {
           setLoading(false);
         }).catch((e) => {
           console.log(e.response.data);
           alert(e.response.data);
         });
       }

       const Error = (message) => {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
   
       const handleClick = (e) => {
        e.preventDefault();
         hiddenFileInput.current.click();
       }
     
       const fileUpload = (e) => {
         e.preventDefault();
         console.log(e);
         setFile(e.target.files[0]);
           uploadProfilePic(e.target.files[0]);
           e.target.value = null;
       }
     
       const fileDrop = (e) => {
         e.preventDefault();
        const file = e.dataTransfer.files[0];
        setFile(file);
        if(e.dataTransfer.files[0]){
         uploadProfilePic(e.dataTransfer.files[0]);
        }
       }
   
 

    const submitHandler = async (e) => {
        e.preventDefault();
        if(SuperAdmin){
          if((endAgeSelected || campaign.end_age) && parseInt(campaign.start_age) <= parseInt(campaign.end_age)){
            setCampaign({...campaign, start_age : parseInt(campaign.start_age), end_age : parseInt(campaign.end_age)})
        await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/edit-campaign/${Campaign._id}`,campaign,{headers : {jwt : isAuthenticated}})
        .then((res) => {
            console.log(res.data);
            navigate('/super-admin/screening');
        }).catch((e) => {
          if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })
        }else{
          Error("Invalid Start and End Age!")
        }
      }else if(User){
        if((endAgeSelected || campaign.end_age) && parseInt(campaign.start_age) <= parseInt(campaign.end_age)){
          setCampaign({...campaign, start_age : parseInt(campaign.start_age), end_age : parseInt(campaign.end_age)})
        await Axios.post(`https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/edit-campaign/${Campaign._id}`,campaign,{headers : {jwt : isAuthenticated}})
        .then((res) => {
            console.log(res.data);
            navigate('/ad-manager');
        }).catch((e) => {
          if(e.response.status == 401){
            Error(e.response.data.message);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
          }
            console.log(e.response.data);
            Error(e.response.data.message);
        })
        }else{
        Error("Invalid Start and End Age!")
        }
      }
    }

    console.log(file);
   

  return (
    <>
    {isAuthenticated && (User || SuperAdmin)?
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet> 
    <Header />
    <ToastContainer autoClose={1000} />
    <div>
  <div className="modal fade map-modal" id="myModal">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="btn-close" data-bs-dismiss="modal" />
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-5">
              <div className="form-wrap">
                <ul className="loca-sec">
                  <li>
                    <label> <input type="radio" name="set-loac" id defaultChecked /> Location</label>
                  </li>
                  <li>
                    <label> <input type="radio" name="set-loac" id /> Radius</label>
                  </li>
                  <li>
                    <label> <input type="radio" name="set-loac" id /> All user</label>
                  </li>
                </ul>
                <div className="sec-radius">
                  <div className="row">
                    <div className="col-lg-6">
                      <h6>Type</h6>
                      <ul className="Type-sec">
                        <li>
                          <input type="radio" id="radio1" name="selector" defaultChecked />
                          <label htmlFor="radio1">Kilometer</label>
                        </li>
                        <li>
                          <input type="radio" id="radio2" name="selector" />
                          <label htmlFor="radio2">Miles</label>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="km-range">
                        <h6>1km</h6>
                        <div className="range-hand">
                          <progress value={30} max={100}> <span /> </progress>
                          <span />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h6>set location <sup>*</sup></h6>
                <input disabled type="text" placeholder="Search location" className="src-place" />
                <ul className="src-resu">
                  <li>Berlin, Germany <i className="fa-solid fa-plus" /></li>
                  <li>Berlin, Germany <i className="fa-solid fa-plus" /></li>
                  <li>Berlin, Germany <i className="fa-solid fa-plus" /></li>
                  <li>Berlin, Germany <i className="fa-solid fa-plus" /></li>
                </ul>
                <ul className="selt-loca">
                  <li>Selected location</li>
                  <li><a href="#">Berlin, Germany <i className="fa-solid fa-xmark" /></a></li>
                  <li><a href="#">Berlin, Germany <i className="fa-solid fa-xmark" /></a></li>
                  <li><a href="#">Berlin, Germany <i className="fa-solid fa-xmark" /></a></li>
                  <li><a href="#">Berlin, Germany <i className="fa-solid fa-xmark" /></a></li>
                  <li><a href="#">Berlin, Germany <i className="fa-solid fa-xmark" /></a></li>
                  <li><a href="#">Berlin, Germany <i className="fa-solid fa-xmark" /></a></li>
                </ul>
                <input type="submit" value="Done" />
              </div>
            </div>
            <div className="col-lg-7">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3264.851828746357!2d-106.68780158447659!3d35.08543477002248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87da551856122831%3A0xfcbb87f98772852f!2sU.S.%20Rt.%2066%2C%20Albuquerque%2C%20NM%2C%20USA!5e0!3m2!1sen!2sin!4v1676008033263!5m2!1sen!2sin" width="100%" height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="create-campaign-page">
    {!SuperAdmin?
   <Sidebar />
   :
   <AdminSidebar /> 
    }
    <div className="page-decp-dec">
      <div className="container">
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-lg-12 title">
              <h2>Edit Campaign</h2>
            </div>
            <div className="col-lg-5">
              <h6>Objective (Beta) <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>Traffic: increase traffic to your ad and website</p>
                    <br />
                    <p>Impressions: increase the amount of exposure to boost brand awareness</p>
                  </div>
                </span></h6>
              <ul className="Traffic">
                <li>
                  <input type="radio" id="radio1" name="selector" defaultChecked />
                  <label htmlFor="radio1">Traffic</label>
                </li>
                <li>
                  <input type="radio" id="radio2" name="selector" />
                  <label htmlFor="radio2">Impressions</label>
                </li>
              </ul>
              <h5>Campaign information </h5>
              <label className="field-title"  htmlFor>Campaign title <sup>*</sup> <span className="tool-tip">
                
                  {/* <div className="decp-tool">
                    <p>Campaign title: this title will only be visible on this portal for own use.</p>
                  </div> */}
                </span></label>
              <input type="text" style={{marginBottom:"0"}} required name="campaign_title" maxLength={30} onChange={inputsHandler} value={campaign.campaign_title} placeholder="Campaign 1" />
              <div className="max-text" style={{marginTop:"0"}}>{campaign.campaign_title.length} / 30</div>
              <label className="field-title" htmlFor>Demographics<span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>select the reach of your campaign with location or radius accuracy.</p>
                  </div>
                </span></label>
              <ul className="deo-sec">
                <li>
                  <label> <input type="radio" name="demographics" disabled  />Location </label>
                </li>
                <li>
                  <label> <input type="radio" name="demographics" disabled /> Radius </label>
                </li>
                <li>
                  <label> <input type="radio" name="demographics" defaultChecked /> All user </label>
                </li>
              </ul>
              <div className="den-sec">
                <input type="text" placeholder="Continent - Country - City - Postcode" disabled />
                <input type="submit" value="Search" disabled />
              </div>

              {/* <a className="add-loc-btn" data-bs-toggle="modal" data-bs-target="#myModal" disabled>+ Add
                location</a> */}

<a className="add-loc-btn" data-bs-toggle="" data-bs-target="" disabled>+ Add
                location</a>
                
              <label className="field-title" htmlFor>Age <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>select the age group you like to target.</p>
                  </div>
                </span></label>
              <div className="row">
                <div className="col-lg-6">
                  <div className="age-sec">
                    <p>From</p>
                    <div className="quantity">
                      <input type="number" required name="start_age"  min={18} max={100}
                         onBlur={(event) => {
                          if (event.target.value > 100) {
                            event.target.value = 100;
                            setCampaign((prevState) => ({ ...prevState, start_age: 100 }));
                          }
                        }}
                       value={campaign.start_age} onChange={inputsHandler}  />
                      <div className="up-arrow"
                      onClick={() => 
                        {
                        setStartAgeSelected(true);
                        if(campaign.start_age < campaign.end_age){
                        if(campaign.start_age >= 100){
                            campaign.start_age = 100;
                        }
                        else if(campaign.start_age > campaign.end_age){
                          campaign.start_age = campaign.end_age;
                        }
                        else{
                        setCampaign({...campaign, start_age : campaign.start_age + 1 })
                        }
                        }
                       }
                      }
                       >
                        <i className="fa-solid fa-angle-up" /></div>
                      <div className="down-arrow" 
                      onClick={() => 
                        {
                        if(campaign.start_age === 18){
                            return ;
                        }else{
                        setCampaign({...campaign, start_age : campaign.start_age - 1 })}
                        }
                        }
                        ><i className="fa-solid fa-angle-down" /></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 text-end">
                  <div className="age-sec">
                    <p>Until</p>
                    <div className="quantity">
                      <input type="number" required name="end_age" min={18} max={100} onChange={inputsHandler}
                      onBlur={(event) => {
                        if (event.target.value > 100) {
                          event.target.value = 100;
                          setCampaign((prevState) => ({ ...prevState, end_age: 100 }));
                        }
                      }}
                       value={campaign.end_age} />
                      <div className="up-arrow"
                        onClick={() => 
                            {
                              
                              if(campaign.end_age === null){
                                setEndAgeSelected(true)
                                setCampaign({...campaign, end_age : 18})
                              }
                              if(campaign.end_age >= campaign.start_age){
                             if(campaign.end_age >= 100){
                                campaign.end_age = 100;
                            }else{
                            setCampaign({...campaign, end_age : campaign.end_age + 1 })}
                            }
                           }
                          }
                      >
                     <i className="fa-solid fa-angle-up" /></div>
                      <div className="down-arrow"
                      onClick={() => 
                        {
                        if(campaign.end_age === 18){
                            return ;
                        }
                        else if(campaign.end_age > campaign.start_age){
                        setCampaign({...campaign, end_age : campaign.end_age - 1 })}
                        }
                        }
                      >
                    <i className="fa-solid fa-angle-down" /></div>
                    </div>
                  </div>
                </div>
              </div>
              <label className="field-title" htmlFor>Gender <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>select the gender you like to target.</p>
                  </div>
                </span></label>
                <ul className="gen-sec">
                <li>
                  <label> <input type="checkbox"
                   onClick={(e) => 
                    {
                      if(e.target.checked){ 
                      setCampaign({...campaign, gender : "female"})
                      }else{
                        setCampaign({...campaign, gender : ""})
                      }
                  }} 
                   checked={campaign.gender == "female" || campaign.gender == "all"} name="gender" value={"female"}  id /> Female </label>
                </li>
                <li>
                  <label> 
                  <input type="checkbox"
                   onClick={(e) => 
                    {
                      if(e.target.checked){ 
                      setCampaign({...campaign, gender : "male"})
                      }else{
                        setCampaign({...campaign, gender : ""})
                      }
                  }} 
                   checked={campaign.gender == "male" || campaign.gender == "all"}  name="gender" value={"male"} id /> Male </label>
                </li>
                <li>
                  <label> <input type="checkbox" checked={campaign.gender == "all"} 
                  onClick={(e) => 
                    {
                      if(e.target.checked){ 
                      setCampaign({...campaign, gender : "all"})
                      }else{
                        setCampaign({...campaign, gender : ""})
                      }
                  }} 
                  name="gender" value={"all"} id /> All </label>
                </li>
              </ul>
            </div>
            <div className="col-lg-5">
              <h6>Ad information</h6>
              <label className="field-title" htmlFor>Ad title <sup>*</sup> <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>create a title for the ad which will be shown in our ads. Make sure it's short,
                      punchy, and will catch the attention of your potential customers.</p>
                  </div>
                </span></label>
                <input type="text" style={{marginBottom:"0"}} maxLength={45} required name="ad_title" onChange={inputsHandler} value={campaign.ad_title} placeholder="Placeholder text" />
              <div className="max-text" style={{marginTop:"0"}}>{campaign.ad_title.length} / 45</div>
              <label className="field-title"  htmlFor>Ad description*<sup>*</sup> <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>make sure the description is short and to the point to attract more leads.</p>
                  </div>
                </span></label>
                <textarea required name="ad_description" onChange={inputsHandler} maxLength={300} value={campaign.ad_description} id placeholder="enter your ad descripction" defaultValue={""} />
              <div className="max-text">{campaign.ad_description.length} / 300</div>
              <label className="field-title" htmlFor>Landing page URL <sup>*</sup> <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>leave here the URL where you want to send your audience to.</p>
                  </div>
                </span></label>
              <input required type="text" name="landing_url" onChange={inputsHandler} value={campaign.landing_url} placeholder="Placeholder text" />
              <label className="field-title" htmlFor>Image <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>Filetype: (JPEG, PNG)</p>
                    <p>Size: max 1MB</p>
                    <p>Dimensions: max 1028x1028 pixels</p>
                  </div>
                </span></label>
        
                {loading && !SuperAdmin?
                  <Loader />
                  :
                  file && !SuperAdmin?
                  <>
                  <h6 style={{color : "#00A69F",fontWeight:"bolder"}}>{file.name}<i style={{cursor:"pointer", marginLeft:"0.35rem", marginTop:"0.35rem"}} onClick={() => {setFile(""); setCampaign({...campaign, image : ""})}} className="fa-solid fa-times"></i></h6>
                  </>
                 :
                  null
                }
                
              {SuperAdmin && campaign.image?
              <div style={{display:"flex",justifyContent:"center"}}>
              <img style={{height:'15rem', borderRadius:"50%", width:"15rem",objectFit:"cover",marginBottom:"2rem"}} src={campaign.image}></img>
              </div>
              :
              User && campaign.image?
              <>
              <img style={{maxHeight:'15rem', width:"100%",objectFit:"contain",marginBottom:"2rem"}} src={campaign.image}></img>
              </>
              :
              <div className="img-up-sec" onDragOver={(e) => e.preventDefault()} onDragEnter={(e) => e.preventDefault()} onDragLeave={(e) => e.preventDefault()} onDrop={fileDrop}>
                <img src="images/img-up-icon.svg" alt="" />
                <h5>Drag &amp; Drop</h5>
                <h4>Your files here or Browse to upload <span>Only JPEG and PNG with max size 1MB </span>
                </h4>
                <input accept="image/png, image/jpeg" type="file" onChange={fileUpload} ref={hiddenFileInput} style={{display: "none"}} />
                  {file?
                  <>
                  {loading?
                  <Loader />
                  :
                  <button className='upload-button' disabled>
                    Uploaded
                  </button>
                  }
                  </>
                  :
                  <Link to="" onClick={handleClick}>Upload</Link> 
                  }
              </div>
              }
              <label className="field-title" htmlFor>Daily budget (mAds) <sup>*</sup> <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                  <div className="decp-tool">
                    <p>select the max amount of mAds which you would like to spend a day.</p>
                  </div>
                </span></label>
              <div className="p-range">
              <input required type="number" disabled={campaign.daily_budget === 0? false : true} name="daily_budget" value={campaign.daily_budget} onChange={inputsHandler} placeholder="10" />
                <i className="fa-solid fa-dollar-sign" />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="field-title" htmlFor>Start date <sup>*</sup> <span className="tool-tip"><img src="images/info-icon.svg" alt="" />
                      <div className="decp-tool">
                        <p>select the date you would like the campaign to start.</p>
                      </div>
                    </span></label>
                  <input required type="date" disabled={campaign.active === "active" || campaign.daily_budget>0? true : false} defaultValue={moment(campaign.start_date).format("YYYY-MM-DD")}  onChange={inputsHandler} name="start_date" id placeholder="Select date" />
                </div>
                <div className="col-lg-6">
                  <label className="field-title" htmlFor>End date <sup>*</sup></label>
                  <input type="date" disabled={campaign.active === "active" || campaign.daily_budget>0? true : false}  defaultValue={moment(campaign.end_date).format("YYYY-MM-DD")} onChange={inputsHandler} name="end_date" id placeholder="Select date" />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              {!SuperAdmin?
              <input type="submit" value="Publish" className="main-sub-btn" />
              :
              <div style={{display:"flex",width:"20rem", justifyContent:"space-between"}}>
              <Link to="/super-admin/screening"><input type="submit" value="Go Back" className="main-sub-btn" /></Link>
              <input type="submit" value="Keep Changes" className="main-sub-btn" />
              </div>
             }
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
<Footer />
</>
     :
     <Navigate to="/login" />
    }
    </>
  )
}

export default EditCampaign