import React, { useEffect, useState } from "react";
import AdminSidebar from "../Components/AdminSidebar";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function CompaniesList() {
  const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");

  const navigate = useNavigate();

  const [companies, setCompanies] = useState([]);

  const [callApi, setCallApi] = useState(false);

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  console.log(isAuthenticated);

  const fetchCompanies = async () => {
    await Axios.get("https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/get-all-companies", {
      headers: { jwt: isAuthenticated },
    })
      .then((res) => {
        console.log(res.data);
        setCompanies(res.data.Companies);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        console.log(e.response.data);
        Error(e.response.data.message);
      });
  };

  const RemoveCompany = async (id) => {
    await Axios.delete(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/delete-company/${id}`, {
      headers: { jwt: isAuthenticated },
    })
      .then((res) => {
        console.log(res.data);
        setCompanies(res.data.Companies);
        setCallApi(!callApi);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        console.log(e.response.data);
        Error(e.response.data.message);
      });
  };

  const changeActiveStatus = async (id) => {
    await Axios.get(`https://he2wkhrdue.eu-central-1.awsapprunner.com/super-admin/change-activity-status/${id}`, {
      headers: { jwt: isAuthenticated },
    })
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        console.log(e.response.data);
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    fetchCompanies();
  }, [callApi]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <Header />
      <ToastContainer autoClose={1000} />
      <section className="companies-list-page">
        <AdminSidebar />
        <div className="page-decp-dec">
          <div className="row">
            {companies?.map((company) => {
              const lastActiveDate = company.last_Active_Time ? company.last_Active_Time.substring(8, 10) + '-' + company.last_Active_Time.substring(5, 7) + '-' + company.last_Active_Time.substring(0, 4) : null;

              return (
                <div className="col-lg-4" key={company._id}>
                  <div className="img-wrap">
                    <img src="/images/camp-list-img1.jpg" alt="" />
                    <Link to="" className="del-btn" onClick={() => RemoveCompany(company._id)}>
                      {" "}
                      <img src="/images/camp-list-img3.svg" alt="" />{" "}
                    </Link>
                  </div>
                  <div className="decp">
                    <img
                      src={company.profileImg ? company.profileImg : "/images/camp-list-img1.jpg"}
                      style={{ width: "101px", height: "100px", objectFit: "cover" }}
                      alt=""
                      className="img1"
                    />
                    <h3 style={{ textTransform: "capitalize" }}>
                      {company.first_name && company.last_name
                        ? `${company.first_name} ${company.last_name}`
                        : company.username}
                    </h3>
                    <h4>Last Active : {lastActiveDate}</h4>
                    <h5>Email</h5>
                    <h6>
                      <a href="#">{company.email}</a>
                    </h6>
                    <h5>Company address</h5>
                    <h6>{company.address_line3}</h6>
                    <h6>{company.address_line2}</h6>
                    <h6>{company.address_line1}</h6>
                    <h5>Wallet</h5>
                    <h6>{company.wallet}</h6>

                    <h5>VAT</h5>
                    <h6>{company.vat_number}</h6>
                    <ul>
                      <li>
                        <Link to="/company-details" state={{ company_id: company._id }}>
                          Check profile
                        </Link>
                      </li>
                      <li>
                        {company.active === 1 ? (
                          <Link to="" onClick={() => changeActiveStatus(company._id)}>
                            Pause
                          </Link>
                        ) : (
                          <Link
                            to=""
                            style={{ color: "#fff", background: "#F8B14F" }}
                            onClick={() => changeActiveStatus(company._id)}
                          >
                            Paused
                          </Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default CompaniesList;
