import  Axios  from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import CsvDownloadButton from 'react-json-to-csv'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function Billing() {

  const navigate = useNavigate(); 

  const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");
  const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User"));

  const [payments, setPayments] = useState([]);
  const [wallet, setWallet] = useState('');
  const [walletOnHold, setWalletOnHold] = useState('');

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }

  const FetchPayments = async () => {
    await Axios.get(`https://he2wkhrdue.eu-central-1.awsapprunner.com/payment/get-all-payments?user=${User._id}`, {headers : {jwt : isAuthenticated}})
    .then((res) => {
      console.log(res.data);
      setWallet(res.data.wallet);
      setWalletOnHold(res.data.wallet_onHold);
      setPayments(res.data.Payments);
    }).catch((e) => {
      if(e.response.status == 401){
        Error(e.response.data.message);
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
      }
        console.log(e.response.data);
        // Error(e.response.data.message);
    })
  }

  useEffect(() => {
    FetchPayments()
  }, [])
  

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
       </Helmet> 
    <Header />
    <ToastContainer autoClose={1000} />
    <section className="billing-page">
  <Sidebar />
  <div className="page-decp-dec">
    <div className="row">
    <div className="col-lg-9 col-md-7">
        <div className="sec1">
          <h2>My <span>mAds</span> Balance</h2>
          <div className="wrap">
            <div className='row'>
            <div className='col-lg-4'>
            <h3>Available</h3>
            <h4>${wallet? wallet.toFixed(2) : 0}</h4>
            </div>
            <div className='col-lg-6 ms-auto shade'>
              
            <h3>Allocated Budget</h3>
            <h4>${walletOnHold? walletOnHold.toFixed(2) : 0}</h4>
            </div>
            </div>
          </div>

        

        </div>
      </div>
      <div className="col-lg-3 col-md-5">
        <div className="sec2">
          <h4>Buy more</h4>
          <h2>mAds</h2>
          <Link to="/payment">Buy now</Link>
        </div>
      </div>
    </div>
    <div className="row align-items-center table-sec">
    <div className="col-lg-6 col-6">
        <h3>All Transaction Details</h3>
      </div>
      <div className="col-lg-6 col-6 text-end">
      <CsvDownloadButton 
      disabled={payments.length === 0? true : false}
      filename="all-transactions"
      style={{background: "#00A69F",
      border: "1px solid #00A69F",
      boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.043)",
      borderRadius: "4px",
      fontWeight: "400",
      lineHeight: "22px",
      textAlign: "center",
      color: "#FFFFFF",
      padding: "10px 12px"}} data={payments} > <i className="fa-solid fa-download" /> Download .CSV</CsvDownloadButton>
      </div>
      <div className="col-lg-12">
        <div className="table-wrap">
          <table>
            <tbody>
              <tr>
                <th>Date</th>
                <th>mAds ID</th>
                <th>Amount</th>
                <th>Payment type</th>
                <th>Status</th>
              </tr>
              {payments.map((payment) => {
                return (
                  <tr>
                  <td>{moment(payment.updatedAt).format(`DD/MM/YYYY`)}</td> 
                  <td>{payment._id}</td>
                  <td>${payment.amount}</td>
                  <td>online</td>
                  <td><a href="#" style={payment.status == "pending" ? {color : "#F8B14F"} : payment.status == "failed"? {color : "red"} : {color : "green"}} className="succ-btn">{payment.status}</a></td>
                </tr>
                )
              })}
           
            </tbody></table>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />
    </>
  )
}

export default Billing