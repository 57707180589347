import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import Axios from "axios";
import CampaignChartYear from "./CampaignChartYear";

const areaChartOptions = {
  chart: {
    height: 450,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 5,
  },
  grid: {
    strokeDashArray: 0,
  },
};

const CampaignChartDays = ({ slot, impr_road, impr_wallet, clicks_link, clicks_wallet, loading, month }) => {
  const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User"));

  const theme = useTheme();
  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  //   console.log(month);

  const daysArray = [];
  const today = new Date();
  const year = today.getFullYear();

  today.setMonth(month);

  const monthName = today.toLocaleString("default", { month: "long" });

  const daysInMonth = new Date(year, month + 1, 1, -1).getDate();

  for (let i = 1; i <= daysInMonth; i++) {
    daysArray.push(i.toString());
  }

  console.log(daysArray);

  console.log(loading);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: ["#DF5D5D", "#F8B14F", "#53D6B7", "#50DB66"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
      },
      xaxis: {
        categories: daysArray,
        // : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
            ],
          },
        },
        axisBorder: {
          show: true,
          color: line,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary],
          },
        },
      },
      grid: {
        borderColor: line,
      },
      tooltip: {
        theme: "light",
      },
    }));
  }, [primary, secondary, line, theme, slot]);

  const [series, setSeries] = useState([
    {
      name: "Impressions Road",
      data: impr_road,
    },
    {
      name: "Impressions Wallet",
      data: impr_wallet,
    },
    {
      name: "Clicks Wallet",
      data: clicks_wallet,
    },
    {
      name: "Clicks Link",
      data: clicks_link,
    },
  ]);

  useEffect(() => {
    setSeries([
      {
        name: "Impressions Road",
        data: impr_road,
      },
      {
        name: "Impressions Wallet",
        data: impr_wallet,
      },
      {
        name: "Clicks Wallet",
        data: clicks_wallet,
      },
      {
        name: "Clicks Link",
        data: clicks_link,
      },
    ]);
  }, []);
  return (
    <>
      <div className="App">
        <h3 style={{ paddingLeft: "550px" }}>{monthName}</h3>
        {series && !loading && (
          <ReactApexChart options={options} legend={false} series={series ? series : null} type="line" height={450} />
        )}
      </div>
    </>
  );
};

CampaignChartDays.propTypes = {
  slot: PropTypes.string,
};

export default CampaignChartDays;
