import React, {useState} from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';

function Sidebar(props) {
  const User =
    JSON.parse(localStorage.getItem("User")) ||
    JSON.parse(sessionStorage.getItem("User"));

  const navigate = useNavigate();

  const location = useLocation();

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  console.log(token);

  const logOut = async (e) => {
    e.preventDefault();
    await Axios.get(`https://he2wkhrdue.eu-central-1.awsapprunner.com/user/logout`,{headers : {jwt : token}})
    .then((res) => {
      console.log(res.data);
      localStorage.clear();
      sessionStorage.clear();
  }).finally(() => {
      navigate('/login');
  }).catch((e) => {
      alert(e);
  })
}


  return (
    <>
      <section className="menu-sec">
        <ul>
          <li
            className={
              location.pathname === "/" ||
              location.pathname === "/business-registration"
                ? "active"
                : "" || location.pathname === "/business-details"
            }
          >
            <Link to="/">
              {" "}
              <img src="/images/menu-icon1.png" alt="" /> Dashboard{" "}
            </Link>
          </li>
          <li
            className={
              location.pathname === "/ad-manager" ||
              location.pathname === "/create-campaign"
                ? "active"
                : "" || location.pathname == "/campaign-performance/"
                ? "active"
                : "" || location.pathname == "/edit-campaign"
                ? "active"
                : ""
            }
          >
            <Link to="/ad-manager">
              {" "}
              <img src="/images/menu-icon2.png" alt="" /> Ad manager{" "}
            </Link>
          </li>
          <li className={location.pathname === "/billing" ? "active" : location.pathname === "/payment" ? "active" : ""}>
            <Link to="/billing">
              {" "}
              <img src="/images/menu-icon3.png" alt="" /> Billing{" "}
            </Link>
          </li>
          <li
            className={
              location.pathname === "/profile" ||
              location.pathname == "/edit-profile/"
                ? "active"
                : ""
            }
          >
            <Link to="/profile">
              {" "}
              <img src="/images/menu-icon4.png" alt="" /> Profile{" "}
            </Link>
          </li>
        </ul>
        <Link onClick={logOut} className="log-out">
          {" "}
          <img src="/images/menu-icon5.svg" alt="" /> LogOut
        </Link>
      </section>
    </>
  );
}

export default Sidebar