import React from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function BusinessDetails() {

  const navigate = useNavigate();

    const User = JSON.parse(localStorage.getItem("User")) || JSON.parse(sessionStorage.getItem("User"));

    const SuperAdmin = JSON.parse(localStorage.getItem("SuperAdmin"));

    const isAuthenticated = localStorage.getItem("token") || sessionStorage.getItem("token");

    const location = useLocation();

    console.log(isAuthenticated);

    const Error = (message) => {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    const getCampaignStats = async () => {
      await Axios.get(`https://he2wkhrdue.eu-central-1.awsapprunner.com/campaign/get-campaigns-by-user/${User._id}`,{headers : {jwt : isAuthenticated}})
      .then((res) => {
          console.log(res.data);
          if(res.data.Campaigns.length === 0){
            navigate('/ad-manager')
          }else{
            navigate('/');
          }
      }).catch((e) => {
        if(e.response.status == 401){
          Error(e.response.data.message);
          localStorage.clear();
          sessionStorage.clear();
          navigate('/login');
        }
          console.log(e.response.data);
          Error(e.response.data.message);
      })
    }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet> 
    <Header />
    <ToastContainer autoClose={1000} />
    <section className={location.pathname === "/profile"? "business-details-page menu" : "business-details-page menu-inactive" }>
  <Sidebar />
  <div className="page-decp-dec">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {User.profileImg? <img src={User.profileImg} style={{maxWidth:"15rem", maxHeight:"15rem", borederRadius:"50%"}} className="pro-img" />  : null}
        </div>
        <div className="col-lg-3">
          <h3>Personal Info</h3>
          <ul className="decp">
            <li>
              <h4>First name</h4>
              <p style={{ textTransform: "capitalize" }}>{User.first_name}</p>
            </li>
            <li>
              <h4>Last name</h4>
              <p style={{ textTransform: "capitalize" }}>{User.last_name}</p>
            </li>
          </ul>
        </div>
        <div className="col-lg-9">
          <h3>Tax info</h3>
          <ul className="decp">
            <li>
              <h4>Chamber of commerce</h4>
              <p>{User.commerce_chamber}</p>
            </li>
            <li>
              <h4>VAT commerce</h4>
              <p>VAT {User.vat_number}</p>
            </li>
            {User.affiliate_code?
            <li>
              <h4>Affiliate Code</h4>
              <p>{User.affiliate_code}</p>
            </li>
            :
            null
            }
          </ul>
        </div>
        <div className="col-lg-3">
          <h3>Company Details</h3>
          <ul className="decp">
            <li>
              <h4>Company name </h4>
              <p style={{ textTransform: "capitalize" }}>{User.company_name} </p>
            </li>
            <li>
              <h4>Address Line 1</h4>
              <p style={{ textTransform: "capitalize" }}>{User.address_line1}</p>
            </li>
            {User.address_line2&&
            <li>
              <h4>Address Line 2</h4>
              <p style={{ textTransform: "capitalize" }}>{User.address_line2}</p>
            </li>
            }
            {User.address_line3&&
            <li>
              <h4>Address Line 3</h4>
              <p style={{ textTransform: "capitalize" }}>{User.address_line3}</p>
            </li>
           }
           <li>
              <h4>City</h4>
              <p style={{ textTransform: "capitalize" }}>{User.city}</p>
            </li>
            <li>
              <h4>State</h4>
              <p style={{ textTransform: "capitalize" }}>{User.state}</p>
            </li>
            <li>
              <h4>Postal Code</h4>
              <p style={{ textTransform: "capitalize" }}>{User.pincode}</p>
            </li>
            <li>
              <h4>Country business</h4>
              <p style={{ textTransform: "capitalize" }}>{User.country}</p>
            </li>
          </ul>
          <ul className="button">
            <li><Link to={ location.pathname === "/profile"? `/edit-profile/?id=${User._id}`  : `/business-registration/?id=${User._id}`}>Edit</Link></li>
            <li><Link onClick={getCampaignStats}>Done</Link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />
    </>
  )
}

export default BusinessDetails